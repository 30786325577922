// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsScheduling-SchedulingSettings-tabs-sections-DrawSection-DrawSection-module__twoCols--DQtNC {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.src-businessServices-services-bsScheduling-SchedulingSettings-tabs-sections-DrawSection-DrawSection-module__title--XvVrX {
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsScheduling/SchedulingSettings/tabs/sections/DrawSection/DrawSection.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AACF;AACA;EACE,gBAAA;AACF","sourcesContent":[".twoCols {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 12px;\n}\n.title {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"twoCols": `src-businessServices-services-bsScheduling-SchedulingSettings-tabs-sections-DrawSection-DrawSection-module__twoCols--DQtNC`,
	"title": `src-businessServices-services-bsScheduling-SchedulingSettings-tabs-sections-DrawSection-DrawSection-module__title--XvVrX`
};
export default ___CSS_LOADER_EXPORT___;
