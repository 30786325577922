import * as React from "react";
import { Select, SelectProps } from "antd";
import type { BaseSelectRef } from "rc-select";
import { CellBox, FnFocus, PropsCellBox } from "./CellBox";

export type PropsCellBoxSelect<TValue> = Omit<
  PropsCellBox<TValue>,
  "children"
> & {
  selectProps: Omit<SelectProps, "onBlur" | "onChange" | "value">;
};

export const CellBoxSelect = <TValue,>(props: PropsCellBoxSelect<TValue>) => {
  const { selectProps, ...cellProps } = props;
  return (
    <CellBox<TValue> {...cellProps}>
      {(chProps) => <Inner<TValue> selectProps={selectProps} {...chProps} />}
    </CellBox>
  );
};

interface PropsInner<TValue> {
  value: TValue | undefined;
  onChange(value: TValue | undefined): void;
  onBlur(): void;
  getFocus: (fn: FnFocus) => void;
  selectProps: PropsCellBoxSelect<TValue>["selectProps"];
}

const Inner = <TValue,>(props: PropsInner<TValue>) => {
  const { selectProps, value, onChange, onBlur, getFocus } = props;
  const ref = React.useRef<BaseSelectRef>(null);
  React.useEffect(() => {
    getFocus(() => {
      ref.current?.focus();
    });
    return () => getFocus(undefined);
  }, [ref.current]);

  const { style, ...restProps } = selectProps;
  const selectStyle = { ...style, width: "100%" };

  return (
    <div style={{ width: "100%" }}>
      <Select
        ref={ref}
        {...restProps}
        style={selectStyle}
        value={value}
        onChange={(v) => {
          onChange(v);
        }}
        onBlur={onBlur}
      />
    </div>
  );
};
