import { UploadOutlined } from "@ant-design/icons";
import { Button, UploadFile, UploadProps } from "antd";
import { t } from "i18next";
import React from "react";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { onError as onCommonError, onError } from "src/common/onError";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  makeFileItem,
  makeUploadMethods,
  onFileListChanged,
  TFilesDict,
  UploadInfo,
  ZUploadResult,
} from "src/components/PlmUploader";
import { AttrImageCarouselProps } from "src/common/attrEdit/components/ZAttrImageCarousel";
import { UploadChangeParam, RcFile } from "antd/es/upload";
import { loadFileInfo } from "src/common/apiFiles";
import { apiFilesUrl } from "src/common/apiUrl";
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadRequestOption } from "rc-upload/lib/interface";
import styles from "./ImageCarousel.module.less";
import { ImageCarouselInner } from "./ImageCarouselInner";
import { ImageCarouselScroll } from "./ImageCarouselScroll";
import { ImageCarouselDragger } from "./ImageCarouselDragger";
import { urlForDownload } from "../PlmUploader/imgSrc";
import { filterClipboardData } from "./filterClipboardData";

type PropsImageCarousel = AttrImageCarouselProps &
  Omit<UploadProps, "fileList" | "onChange"> & {
    value?: string[];
    onChange?(v: string[] | undefined): void;
  };

export const ImageCarousel: React.FC<PropsImageCarousel> = (props) => {
  const {
    value,
    onChange,
    disabled,
    multiple,
    maxCount,
    maxSize,
    accept,
    imageHeight,
    ...uploadProps
  } = props;
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [dict, setDict] = React.useState<TFilesDict>({});
  const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
  const [isMultiMode, setIsMultiMode] = React.useState(false);
  const [activeImage, setActiveImage] = React.useState(0);
  const uploadMaxCount = (multiple ? maxCount : undefined) ?? 1;

  React.useEffect(() => {
    const guids: string[] = value ?? [];
    // Данное изменение добавлено с целью исключить удаление из отображения элементов в состоянии error
    // при этом на отправку данных, это никак не влияет, так как функция onFileListChange чистит все, кроме done
    setFileList((prev) => [
      ...prev.filter((x) => x.status === "error"),
      ...guids.map((uid) => {
        const file = dict[uid];
        return file ?? makeFileItem(true, uid);
      }),
    ]);
    guids
      .filter((uid) => !dict[uid])
      .map((uid) =>
        loadFileInfo(uid)
          .then(({ fileName, size }) => {
            const file: UploadFile = makeFileItem(true, uid, fileName, size);
            setDict((prev) => ({ ...prev, [uid]: file }));
            setFileList((prev) =>
              prev.map((item) => (item.uid === uid ? file : item)),
            );
          })
          .catch(onCommonError),
      );

    if (guids.length > 1 && multiple) {
      setIsMultiMode(true);
    } else {
      setIsMultiMode(false);
      setActiveImage(0);
    }
  }, [value]);

  const { upload, beforeUpload } = makeUploadMethods(true, fileList, maxSize);

  const handleChange = async (info: UploadChangeParam) => {
    const { status, response } = info.file;
    const curFileList = info.fileList;
    setFileList(curFileList);
    if (status === "done") {
      // eslint-disable-next-line no-param-reassign
      info.file.uid = response.filePath;
      setDict((prev) => ({
        ...prev,
        [response.filePath]: info.file as RcFile,
      }));
      setFileList(curFileList);
    }
    const isReadyForOnChange = curFileList.every(
      ({ status: itemStatus }) => itemStatus !== "uploading",
    );
    if (isReadyForOnChange) {
      await onFileListChanged(curFileList, onChange);
    }
  };

  const onRemove = (file: UploadFile) => {
    const newFileList = fileList.filter(({ uid }) => uid !== file.uid);
    setFileList(newFileList);
    onFileListChanged(newFileList, onChange);
  };

  const imageUploadProps: UploadProps = {
    ...uploadProps,
    fileList,
    customRequest: async (options) => upload(options),
    onChange: handleChange,
    onRemove,
    disabled,
    maxCount: uploadMaxCount,
    multiple,
    beforeUpload,
    accept,
  };

  const handleDelete = (index: number) => {
    const fileToDelete = fileList[index];
    if (!fileToDelete) return;
    onRemove(fileToDelete);
    const newActiveImage = index - 1;
    if (newActiveImage >= 0) {
      setActiveImage(newActiveImage);
    }
  };

  const handleChangeOrder = (list: UploadFile[]) => {
    setFileList(list);
    onFileListChanged(list, onChange);
  };

  const handlePaste = async (e: ClipboardEvent) => {
    // Поскольку при вставке из буфера мы не можем пользоваться всем функционалом Upload,
    // приходится устанавливать состояния загрузки в fileList,
    // а также выполнять все необходимые проверки перед загрузкой файлов (accept, maxCount, maxSize) вручную
    const files = filterClipboardData(
      Array.from(e.clipboardData?.items || []).map((item) => item.getAsFile()),
      uploadMaxCount,
      fileList,
      maxSize,
      accept,
    );

    const loadingFiles: UploadFile[] = files.map((file) => ({
      uid: `${file?.name ?? ""}-loading`,
      name: "",
      status: "uploading",
    }));
    setFileList((prevFileList) => [...prevFileList, ...loadingFiles]);

    try {
      const newFiles = await Promise.all(
        files.map(
          (file) =>
            new Promise<UploadFile>((resolve) => {
              if (!file) return;
              const uploadOptions: UploadRequestOption<ZUploadResult> = {
                file,
                onSuccess: (result) => {
                  const { filePath } = result;
                  const newFile: UploadFile = {
                    uid: filePath,
                    name: file.name,
                    status: "done",
                    url: urlForDownload(true, filePath),
                    originFileObj: file as RcFile,
                  };
                  setDict((prev) => ({ ...prev, [filePath]: newFile }));
                  resolve(newFile);
                },
                onError: () =>
                  resolve({
                    uid: `${file.name}-error`,
                    name: file.name,
                    status: "error",
                  }),
                action: apiFilesUrl(""),
                method: "POST",
              };
              upload(uploadOptions);
            }),
        ),
      );

      const updatedFileList = [...fileList, ...newFiles];
      setFileList(updatedFileList);
      onFileListChanged(updatedFileList, onChange);
    } catch (error) {
      onError(error);
    }
  };

  React.useEffect(() => {
    if (isSettingsOpen) document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [fileList, isSettingsOpen]);

  return (
    <div className={styles.imageCarousel}>
      {fileList[activeImage] && (
        <ImageCarouselInner
          onDelete={() => handleDelete(activeImage)}
          disabled={disabled ?? false}
          height={imageHeight}
          fileList={fileList}
          currentImageIndex={activeImage}
          onImageSelect={setActiveImage}
        />
      )}
      {isMultiMode && (
        <ImageCarouselScroll
          fileList={fileList}
          onImageSelect={setActiveImage}
          currentImageIndex={activeImage}
        />
      )}
      <Button
        onClick={() => setIsSettingsOpen(true)}
        disabled={disabled}
        className={styles.imageUploadButton}
        icon={fileList.length > 0 ? undefined : <UploadOutlined />}
      >
        {fileList.length > 0 ? t("Modify") : t("Upload")}
      </Button>
      <ModalVertFixed
        height="80vh"
        open={isSettingsOpen}
        title={t("Image modification")}
        className={styles.imageUploadModal}
        closable={false}
        maskClosable={false}
        footer={
          <div className={styles.modalFooter}>
            <Button
              className={styles.finishButton}
              type="primary"
              onClick={() => setIsSettingsOpen(false)}
            >
              {t("Finish")}
            </Button>
          </div>
        }
      >
        {fileList?.length >= uploadMaxCount && (
          <UploadInfo
            maxCount={uploadMaxCount}
            accept={accept}
            maxSize={maxSize}
          />
        )}
        <ImageCarouselDragger
          uploadProps={imageUploadProps}
          maxSize={maxSize}
          fileList={fileList}
          onOrderChange={handleChangeOrder}
          onDelete={handleDelete}
        />
      </ModalVertFixed>
    </div>
  );
};

ImageCarousel.defaultProps = {
  value: undefined,
  onChange: undefined,
};
