import * as React from "react";
import { Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import { CellBoxNumber } from "src/components/Sheet/CellBoxNumber";
import { CellBoxInput } from "src/components/Sheet/CellBoxInput";
import { CellBoxCheckbox } from "src/components/Sheet/CellBoxCheckbox";
import { CellBox } from "src/components/Sheet/CellBox";
import { onError } from "src/common/onError";
import { MCColumn, mkKey } from "../MCColumn";
import { ZMCPoint, ZMCRow } from "../../../ZMeasurementChart";
import { MChartEntityStore } from "../../MChartEntityStore";
import { MCSubtitle } from "./MCSubtitle";
import { MCTitle } from "./MCTitle";
import { PointSelectExt } from "../PointSelectExt";
import { makeMcPointKey, makeMcVersionKey } from "../../mChartCellKeys";
import styles from "./MChartTable.module.less";
import { PointOpt } from "./PointDict";

export const buildColumns = (
  store: MChartEntityStore,
  hiddenColumns: Set<string>,
): MCColumn[] => [
  {
    key: "select",
    title: <AllCheck store={store} />,
    width: "34px",
    align: "center",
    rowSpan: 2,
    cell: (row) => <RowCheck row={row} store={store} />,
  },
  {
    key: "Point",
    title: "Point",
    width: "150px",
    rowSpan: 2,
    cell: (row) => <PointSelect row={row} store={store} />,
  },
  {
    key: "Description",
    title: "Description",
    width: "200px",
    rowSpan: 2,
    cell: (row) => (
      <CellBoxInput<ZMCPoint["description"]>
        store={store.sheetStore}
        cellKey={makeMcPointKey(row, "description")}
        value={row.mcPoint.description}
        save={(value) =>
          store.savePointValue(row, "description", value ?? null)
        }
        checkValue={(v) => (v ? null : "Required")}
        inputProps={{ allowClear: true }}
      />
    ),
  },
  {
    key: "qc",
    title: "QC",
    width: "40px",
    cls: styles.fullCentered,
    rowSpan: 2,
    cell: (row) => (
      <CellBoxCheckbox
        store={store.sheetStore}
        cellKey={makeMcPointKey(row, "qc")}
        value={row.mcPoint.qc}
        save={(value) => store.savePointValue(row, "qc", value ?? false)}
      />
    ),
  },
  {
    key: "resizeValueMinus",
    title: "Scale -",
    width: "60px",
    rowSpan: 2,
    cell: (row) => (
      <CellBoxNumber<ZMCPoint["resizeValueMinus"]>
        cellKey={makeMcPointKey(row, "resizeValueMinus")}
        value={row.mcPoint.resizeValueMinus}
        store={store.sheetStore}
        save={(value) =>
          store.savePointValue(row, "resizeValueMinus", value ?? null)
        }
        numberProps={{ min: 0 }}
      />
    ),
  },
  {
    key: "resizeValuePlus",
    title: "Scale +",
    width: "60px",
    rowSpan: 2,
    cell: (row) => (
      <CellBoxNumber<ZMCPoint["resizeValuePlus"]>
        cellKey={makeMcPointKey(row, "resizeValuePlus")}
        value={row.mcPoint.resizeValuePlus}
        store={store.sheetStore}
        save={(value) =>
          store.savePointValue(row, "resizeValuePlus", value ?? null)
        }
        numberProps={{ min: 0 }}
      />
    ),
  },
  {
    key: "tolMinus",
    title: "Tol -",
    width: "70px",
    rowSpan: 2,
    cell: (row) => (
      <CellBoxNumber
        cellKey={makeMcPointKey(row, "tolMinus")}
        value={row.mcPoint.tolMinus}
        store={store.sheetStore}
        save={(value) => store.savePointValue(row, "tolMinus", value ?? null)}
        checkValue={(value) => (typeof value !== "number" ? "Required" : null)}
      />
    ),
  },
  {
    key: "tolPlus",
    title: "Tol +",
    width: "70px",
    rowSpan: 2,
    cell: (row) => (
      <CellBoxNumber
        cellKey={makeMcPointKey(row, "tolPlus")}
        value={row.mcPoint.tolPlus}
        store={store.sheetStore}
        save={(value) => store.savePointValue(row, "tolPlus", value ?? null)}
        checkValue={(value) => (typeof value !== "number" ? "Required" : null)}
      />
    ),
  },
  ...store.sizeColumns.flatMap(({ name: szName }) => {
    const verSet = store.sizeVersions[szName];
    const srcList: string[] = verSet ? Array.from(verSet) : [];
    const verList: string[] = srcList.filter(
      (verName) => !hiddenColumns.has(mkKey(szName, verName)),
    );
    return verList.map(
      (verName, i) =>
        ({
          key: mkKey(szName, verName),
          szName,
          verName,
          noPadTitle: true,
          title:
            i === 0 ? (
              <MCTitle
                szName={szName}
                onAddVersion={
                  store.canAddVersion(szName)
                    ? () => store.addVersion(szName)
                    : undefined
                }
                disabled={!!store.buzy}
              />
            ) : null,
          colSpan: i === 0 ? verList.length : undefined,
          subTitle: (
            <MCSubtitle
              verName={verName}
              onDelete={
                store.canDeleteVersion(szName, verName)
                  ? () => store.deleteVersion(szName, verName)
                  : undefined
              }
            />
          ),
          width: "1fr",
          isBaseSize: szName === store.curBaseSizeName,
          cell: (row) => (
            <CellBoxNumber<number | undefined>
              cellKey={makeMcVersionKey(row.mcPoint.id, szName, verName)}
              value={store.getVersionValue(row, szName, verName)}
              store={store.sheetStore}
              checkValue={(v: number | undefined) =>
                typeof v === "number" ? null : "Required"
              }
              save={(value) =>
                store.saveVersionValue(row, szName, verName, value ?? 0)
              }
            />
          ),
        }) satisfies MCColumn,
    );
  }),
];

interface RowProps {
  row: ZMCRow;
  store: MChartEntityStore;
}

interface TableProps {
  store: MChartEntityStore;
}

const AllCheck: React.FC<TableProps> = observer(({ store }) => (
  <Checkbox
    checked={store.selectStatus === "all"}
    indeterminate={store.selectStatus === "partial"}
    onChange={() => store.toggleSelectAll()}
  />
));

const RowCheck: React.FC<RowProps> = observer(({ row, store }) => (
  <Checkbox
    checked={store.selected.has(row.mcPoint.id)}
    onChange={() => store.toggleSelect(row.mcPoint.id)}
  />
));

const PointSelect: React.FC<RowProps> = observer(({ row, store }) => {
  const {
    mcPoint: {
      point: { id, name },
      description,
    },
  } = row;
  const isValueExist = !!id && !!name && !!description;
  const cellValue = isValueExist
    ? {
        value: id,
        label: name,
        desc: description,
      }
    : undefined;

  // В стандартном виде редактирование ячеек не подразумевает изменения других ячеек при успехе
  // Необходима функция, которая будет повторять поведение сохранения ячейки descriotion, синхронизируя состояния wait
  const saveNameDescValue = async ({ label, value, desc }: PointOpt) => {
    const descCellKey = makeMcPointKey(row, "description");
    try {
      store.sheetStore.setWait(descCellKey);
      // При Promise.all сервер выдает ошибку о том, что строка уже редактируется, остается только вызывать методы последовательно
      await store.savePointValue(row, "point", {
        id: value,
        name: label,
      });
      await store.savePointValue(row, "description", desc);
    } catch (e) {
      onError(e);
      store.sheetStore.setError(descCellKey, e.message);
    } finally {
      store.sheetStore.clearWait(descCellKey);
    }
  };
  return (
    <CellBox<PointOpt | undefined>
      cellKey={makeMcPointKey(row, "point")}
      value={cellValue}
      store={store.sheetStore}
      save={async (v) => {
        if (v) await saveNameDescValue(v);
      }}
      checkValue={(v) => (typeof v === "object" ? null : "Required")}
    >
      {({ value, onChange, getFocus, onBlur }) => (
        <PointSelectExt
          store={store}
          value={value}
          onChange={(v) => {
            onChange(v);
            onBlur();
          }}
          getFocus={getFocus}
        />
      )}
    </CellBox>
  );
});
