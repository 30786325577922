import React from "react";
import { Form, Switch } from "antd";
import { AttrTypeName } from "src/types/AttrType";
import { DrawTab } from "./DrawTab";
import { ZSchedSettingsTaskTemplate } from "../../SchedulingTypes";
import { TaskTiesSection } from "./sections";
import { DrawSection } from "./sections/DrawSection";

const fkey = (name: keyof ZSchedSettingsTaskTemplate) => name;

export const TaskTemplateFields: React.FC = () => (
  <DrawTab>
    <Form.Item
      name={["taskTemplateSettings", fkey("showByCondition")]}
      label="Отображать шаблон в зависимости от состояния объекта"
    >
      <Switch />
    </Form.Item>
    <DrawSection<ZSchedSettingsTaskTemplate>
      tabKey="taskTemplateSettings"
      objKey="templateObjectId"
      objType="taskTemplate"
      objLabel="Объект для формирования шаблона задачи"
      title="Список обязательных позиций"
      attrs={[
        {
          attrKey: "nameAttributeId",
          attrLabel: "Укажите атрибут названия задачи",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: "durationAttributeId",
          attrLabel: "Укажите атрибут длительности задачи",
          attrType: AttrTypeName.int,
        },
        {
          attrKey: "groupAttributeId",
          attrLabel: "Укажите атрибут принадлежности задачи к группе задач",
          attrType: AttrTypeName.object,
        },
      ]}
    />
    <TaskTiesSection objType="taskTemplateTies" tabKey="taskTemplateSettings" />
  </DrawTab>
);
