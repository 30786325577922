import * as React from "react";
import axios from "axios";
import { Button, Checkbox, Flex, Form, FormRule, Input, InputRef } from "antd";
import { observer } from "mobx-react-lite";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { onError } from "src/common/onError";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { apiObjUrl } from "src/common/apiUrl";
import { safeFocus } from "src/common/safeFocus";
import { CommonNodeO2, ObjectO2 } from "../../../Obj2Nodes";
import { copyObject, CopyParams } from "./copyObject";
import { Obj2TabStore } from "../../../Obj2TabStore";
import styles from "./ObjectCopyModal.module.less";
import { getTypeIcon } from "../../../utils/typeIcons";

const CheckboxGroup = Checkbox.Group;

interface PropsObjectCopyModal {
  open: boolean;
  close(): void;
  objNode: ObjectO2;
  store: Obj2TabStore;
}

export const ObjectCopyModal: React.FC<PropsObjectCopyModal> = observer(
  (props) => {
    const { open, close, objNode, store } = props;
    const [saving, setSaving] = React.useState(false);
    const onSubmit = (values: CopyParams) => {
      setSaving(true);
      copyObject(objNode.object.id, values)
        .then((newObj) => {
          store.onNewObject(newObj);
          close();
        })
        .finally(() => setSaving(false))
        .catch(onError);
    };
    const inpRef = React.useRef<InputRef>(null);
    const [form] = Form.useForm<CopyParams>();
    React.useEffect(() => {
      if (open) form.resetFields();
      safeFocus(inpRef.current);
    }, [open]);
    const { attrList = [] } = objNode;
    const attrOptions = React.useMemo(
      () =>
        attrList
          .filter(({ systemAttributeType }) => !systemAttributeType)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ id, name, valueType }) => ({
            value: id,
            label: (
              <Flex align="center" gap={5}>
                {getTypeIcon(valueType)}
                {name}
              </Flex>
            ),
          })),
      [attrList],
    );
    const attrValues = Form.useWatch("attrs", form);
    const all = attrValues?.length === attrOptions.length;
    const none = !attrValues?.length;
    return (
      <ModalVertFixed
        title="Копирование/экспорт объекта"
        open={open}
        onCancel={close}
        modalRender={(content) => (
          <Form<CopyParams>
            className={styles.form}
            layout="vertical"
            initialValues={{
              name: `Копия ${objNode.object.name}`,
              attrs: attrOptions.map(({ value }) => value),
            }}
            onFinish={onSubmit}
            form={form}
          >
            {content}
          </Form>
        )}
        footer={
          <Flex justify="space-between" align="center" gap={12}>
            <Button
              href={axios.getUri({
                url: apiObjUrl("/data-transfer/export"),
                params: {
                  objectId: objNode.object.id,
                  attributeIds: attrValues,
                },
              })}
              download={`${objNode.object.name}.xml`}
            >
              Экспорт
            </Button>
            <Flex align="center" gap={12}>
              <Button onClick={close}>Отменить</Button>
              <Button htmlType="submit" type="primary" loading={saving}>
                Копировать
              </Button>
            </Flex>
          </Flex>
        }
      >
        <div className={styles.formContent}>
          <Form.Item
            name="name"
            label="Название"
            rules={[trimRequired(), uniqueObjName(store.treeData)]}
          >
            <Input ref={inpRef} />
          </Form.Item>
          <Checkbox
            indeterminate={!all && !none}
            checked={all}
            onChange={() => {
              form.setFieldValue(
                "attrs",
                all ? [] : attrOptions.map(({ value }) => value),
              );
            }}
          >
            Все атрибуты ({attrValues?.length}/{attrOptions.length})
          </Checkbox>
          <Form.Item
            name="attrs"
            label="Атрибуты"
            className={styles.attrsBox}
            rules={[{ required: true }]}
          >
            <CheckboxGroup options={attrOptions} className={styles.attrsList} />
          </Form.Item>
        </div>
      </ModalVertFixed>
    );
  },
);

const uniqueObjName = (objects: CommonNodeO2[]): FormRule => ({
  validator(rule: unknown, value: string | null) {
    if (value) {
      const tval = value.trim();
      const obj = objects.find(
        (node) => node.type === "obj" && node.object.name === tval,
      );
      if (obj)
        return Promise.reject(
          Error(`Объект с именем "${tval}" уже существует`),
        );
    }
    return Promise.resolve();
  },
});
