import { makeAutoObservable } from "mobx";

export interface Cell {
  key: string;
  focus(): void;
}

export class SheetStore {
  constructor() {
    makeAutoObservable(this);
  }

  activeKey: string | null = null;

  setActiveKey(key: string) {
    this.activeKey = key;
  }

  curCell: Cell | null = null;

  setCurCell(cell: Cell | null) {
    this.curCell = cell;
  }

  tryToSetActive(cell: Cell): boolean {
    const { curCell } = this;
    const { key: cellKey } = cell;
    if (curCell) {
      const err = this.getError(curCell.key);
      if (err) {
        curCell.focus();
        return false;
      }
    }
    this.setActiveKey(cellKey);
    this.setCurCell(cell);
    return true;
  }

  errors: Record<string, string | null> = {};

  getError(key: string): string | null {
    return this.errors[key] ?? null;
  }

  setError(key: string, error: string | null) {
    this.errors[key] = error;
  }

  waits = new Set<string>();

  setWait(key: string) {
    this.waits.add(key);
  }

  clearWait(key: string) {
    this.waits.delete(key);
  }

  // Значения всех редактируемых элементов решено хранить тут.
  cellValues: Record<string, unknown> = {};

  setCellValue(key: string, value: unknown) {
    this.cellValues[key] = value;
  }

  getCellValue<T>(key: string) {
    return this.cellValues[key] as T | undefined;
  }
}
