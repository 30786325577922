import { makeDictionary } from "src/common/makeDictionary";
import { BusinessServiceDescriptor } from "./BusinessServiseDescriptor";
import { bsRegistration } from "./services/bsRegistration";
import { bsBom2 } from "./services/bsBom2";
import { bsChat } from "./services/bsChat/bsChat";
import { bsMeasurementChart } from "./services/bsMeasurementChart/bsMeasurementChart";
import { bsCareLabel, bsTechpack } from "./services/bsTechpack";
import { bsScheduling } from "./services/bsScheduling";

export const allBusinessServices: BusinessServiceDescriptor[] = [
  bsBom2,
  bsRegistration,
  bsChat,
  bsMeasurementChart,
  bsTechpack,
  bsCareLabel,
  bsScheduling,
];

export const businessServicesDict = makeDictionary(
  allBusinessServices,
  ({ key }) => key,
);
