import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { ScrollableTable } from "src/components/ScrollableTable";
import { AColumn } from "src/components/tables/AsyncTable";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import { TableStore } from "src/components/tables/TableStore";
import { onError } from "src/common/onError";
import { FilterFieldsDict } from "src/components/tables/FiltersForm";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { filterMonoSearch } from "src/components/tables/FiltersForm/filterMonoSearch";
import { KeepSelected } from "src/components/tables/KeepSelected";
import { FilterBom2Material, ZBom2Material } from "../ZBom2Material";
import { loadBom2Materials } from "../apiBom2Position";
import styles from "./SelectBomMaterial.module.less";

interface PropsSelectBomMaterial {
  materialTypeId: number;
  multi: boolean;
  open: boolean;
  close(): void;
  exec(list: ZBom2Material[]): Promise<void>;
}

export const SelectBomMaterial: React.FC<PropsSelectBomMaterial> = observer(
  (props) => {
    const { multi, open, close, exec, materialTypeId } = props;
    const [materialSelectStore, setMaterialSelectStore] =
      React.useState<TableStore<ZBom2Material, FilterBom2Material> | null>(
        null,
      );
    const [saving, setSaving] = React.useState(false);
    React.useEffect(() => {
      if (open) {
        setMaterialSelectStore(
          new TableStore({
            rowKey: "id",
            fnLoad: async ({ page, pageSize, filters }) => {
              const { content, totalElements } = await loadBom2Materials(
                materialTypeId,
                page,
                pageSize,
                filters?.query,
              );
              return { rows: content, totalItems: totalElements };
            },
            selectionSettings: {
              selectionType: multi ? "checkbox" : "radio",
              keepSelected: true,
            },
          }),
        );
      } else {
        setMaterialSelectStore(null);
      }
    }, [open]);
    const columns: AColumn<ZBom2Material>[] = React.useMemo(
      () => [
        {
          key: "materialType",
          title: t("Material"),
          render: (_, row) => row.materialType,
        },
        {
          key: "article",
          title: t("Product article"),
          render: (_, row) => row.article,
        },
        {
          key: "name",
          title: t("Heading"),
          render: (_, row) => row.name,
        },
        {
          key: "supplierName",
          title: t("Supplier"),
          render: (_, row) => row.supplierName,
        },
        {
          key: "image",
          title: t("Image"),
          render: (_, row) => (
            <img
              src={imgSrc(row.image)}
              style={{ maxWidth: 64, maxHeight: 64 }}
              alt=""
            />
          ),
        },
      ],
      [],
    );
    const onOk = () => {
      if (materialSelectStore?.selected.length) {
        setSaving(true);
        exec(materialSelectStore.selected)
          .then(close)
          .finally(() => setSaving(false))
          .catch(onError);
      }
    };
    const filterItems: FilterFieldsDict<FilterBom2Material> = {
      query: filterMonoSearch(),
    };
    return (
      <ModalVertFixed
        centered
        destroyOnClose
        width="1100px"
        height="85vh"
        title={t("Material")}
        open={!!materialSelectStore}
        onCancel={close}
        okText={t("Add")}
        okButtonProps={{
          disabled: !materialSelectStore?.selected.length,
          loading: saving,
        }}
        onOk={onOk}
      >
        {!!materialSelectStore && (
          <div className={styles.tableBox}>
            <KeepSelected
              store={materialSelectStore}
              placeholder={multi ? "Selected materials" : "Selected material"}
              makeTag={(r) => r.name}
            />
            <ScrollableTable
              store={materialSelectStore}
              columns={columns}
              filterItems={filterItems}
              onRowClick={(row) => selectOnRowClick(row, materialSelectStore)}
            />
          </div>
        )}
      </ModalVertFixed>
    );
  },
);
