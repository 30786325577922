import * as React from "react";
import { observer } from "mobx-react-lite";
import { VerticalLayout } from "src/components/VerticalLayout";
import { ZIdName } from "src/types/ZIdName";
import { Form } from "antd";
import { SelectObject } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectObject";
import { BomSection } from "./BomSection";

interface PropsBom2Settings {
  loadObjectOptions: () => Promise<ZIdName[]>;
}

export const Bom2Settings: React.FC<PropsBom2Settings> = observer((props) => {
  const { loadObjectOptions } = props;
  return (
    <VerticalLayout>
      <Form.Item
        label="BOM object"
        name="bomObjectId"
        rules={[{ required: true }]}
      >
        <SelectObject loadObjectOptions={loadObjectOptions} />
      </Form.Item>
      <Form.Item
        label="Material object"
        name="materialObjectId"
        rules={[{ required: true }]}
      >
        <SelectObject loadObjectOptions={loadObjectOptions} />
      </Form.Item>
      <BomSection
        partKey="positionAttributes"
        objectAttr="positionObjectId"
        label="Position"
        attrs={[
          ["nameId", "Name"],
          ["materialTypeId", "Material type"],
          ["materialLocationId", "Material location"],
          ["materialSubtypeId", "Material subtype"],
          ["imageId", "Image"],
          ["locationId", "Location"],
          ["trimSizeId", "Trim size"],
          ["amountId", "Amount"],
          ["commentId", "Comment"],
          ["qualityDetailsId", "Quality details"],
          [
            "linkToBomPositionColorModelsId",
            "Link To Bom Position Color Models",
          ],
        ]}
        loadObjectOptions={loadObjectOptions}
      />
      <BomSection
        partKey="supplierAttributes"
        objectAttr="supplierObjectId"
        label="Supplier"
        attrs={[["nameId", "Name"]]}
        loadObjectOptions={loadObjectOptions}
      />
      <BomSection
        partKey="colorAttributes"
        objectAttr="colorObjectId"
        label="Color"
        attrs={[
          ["linkToModelId", "linkToModelId"],
          ["colorModelArticleId", "colorModelArticleId"],
          ["linkToColorInternalId", "linkToColorInternalId"],
        ]}
        loadObjectOptions={loadObjectOptions}
      />
      <BomSection
        partKey="bomPosColorModelAttributes"
        objectAttr="colorModelObjectId"
        label="Color Model"
        attrs={[
          ["linkToBomPositionId", "Bom Position"],
          ["linkToColorModelId", "Color Model"],
          ["linkToColorId", "Color"],
          ["colorManualId", "Color Manual"],
          ["colorReferenceId", "Color Reference"],
        ]}
        loadObjectOptions={loadObjectOptions}
      />
    </VerticalLayout>
  );
});
