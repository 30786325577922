import * as React from "react";
import { ZIdName } from "src/types/ZIdName";
import { Form, Select } from "antd";
import { ZAttribute } from "src/types/ZAttribute";
import { onError } from "src/common/onError";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { SelectObject } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectObject";
import { ZBom2Settings } from "../../Bom2Types";
import styles from "./BomSection.module.less";

interface PropsBomSection<PartKey extends keyof ZBom2Settings> {
  objectAttr: keyof ZBom2Settings;
  partKey: PartKey;
  label: string;
  attrs: [keyof ZBom2Settings[PartKey], string][];
  loadObjectOptions: () => Promise<ZIdName[]>;
}

export const BomSection = <PartKey extends keyof ZBom2Settings>(
  props: PropsBomSection<PartKey>,
): React.ReactElement => {
  const form = Form.useFormInstance();
  const { objectAttr, label, attrs, loadObjectOptions, partKey } = props;
  const objId = Form.useWatch(objectAttr);
  const [attrList, setAttrList] = React.useState<ZAttribute[]>([]);
  const attrName = (key: keyof ZBom2Settings[PartKey]): [string, string] => [
    partKey,
    String(key),
  ];
  React.useEffect(() => {
    setAttrList([]);
    if (typeof objId === "number") {
      loadObjectAttrinbutesAll(objId).then(setAttrList).catch(onError);
    }
  }, [objId]);
  return (
    <div className={styles.section}>
      <Form.Item
        label={label}
        name={objectAttr}
        rules={[{ required: true }]}
        className={styles.objectItem}
      >
        <SelectObject
          loadObjectOptions={loadObjectOptions}
          onChange={() => {
            attrs.forEach(([attrKey]) =>
              form.setFieldValue(attrName(attrKey), undefined),
            );
          }}
        />
      </Form.Item>
      <div className={styles.attrList}>
        {attrs.map(([attrKey, attrLabel]) => (
          <Form.Item
            key={String(attrKey)}
            label={attrLabel}
            name={attrName(attrKey)}
            rules={[{ required: true }]}
          >
            <Select
              options={attrList}
              fieldNames={{ label: "name", value: "id" }}
              allowClear
            />
          </Form.Item>
        ))}
      </div>
    </div>
  );
};
