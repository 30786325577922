import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Collapse,
  Flex,
  Form,
  Radio,
  Space,
  Switch,
  notification,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { IdLabel } from "src/references/getIdNames";
import { AttrTypeName, isAttrDictionary } from "src/types/AttrType";
import { findNode } from "src/common/findNode";
import { ZAttribute } from "src/types/ZAttribute";
import { isIteratorAvailable } from "src/pages/ManagementPage/objIterator";
import { VerticalContent } from "src/components/VerticalContent";
import { FieldsWithTitle } from "src/pages/ManagementPage/FieldsWithTitle";
import { WidthLimitedFields } from "src/pages/ManagementPage/WidthLimitedFields";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { onError } from "src/common/onError";
import { SelectAttribute, buildTreeForSrcDict } from "../SelectAttribute";
import { edAttrField } from "../../EdAttribute";
import { Obj2TabStore } from "../../Obj2TabStore";
import { findNodeOwnerObject } from "../../utils/findNodeOwnerObject";
import { AttributeO2, CommonNodeO2, newItemId } from "../../Obj2Nodes";
import { RestrictionsTableField } from "./RestrictionsTableField";
import { RolesEditor } from "../../roles/RolesEditor";
import { UpToObject } from "../UpToObject";
import { AttrCommonFields } from "./AttrCommonFields";
import styles from "./AttrForm2.module.less";
import { CopyOnStatesButton } from "../../roles/CopyOnStatesButton";
import { PermissionsCopyType } from "../../roles/roleTypes";
import { CopyOnAttsButton } from "../../roles/CopyOnAttsButton";

/**
 * Внутри формы формат полей соответствует EdAttribute
 */

interface PropsAttrForm2 {
  store: Obj2TabStore;
  attrNode: AttributeO2;
}

export const AttrForm2: React.FC<PropsAttrForm2> = observer(
  ({ store, attrNode }) => {
    const { attr, states = [] } = attrNode;
    const valueType = Form.useWatch(edAttrField.valueType);
    const typeName: AttrTypeName = store.attrTypesDict[
      valueType
    ] as AttrTypeName;
    const objIteratorMode = isIteratorAvailable(
      attrNode,
      typeName,
      store.treeData,
    );
    const useRestriction = Form.useWatch(edAttrField.useRestriction);
    const disabled = !attrNode.actions.update;
    const restrChangeDisabled = !!attrNode.attr.parentAttributeId;

    const typeFilter = (typeId: number): boolean =>
      isAttrDictionary(store.attrTypesDict[typeId]);
    const dictVisible = typeFilter(valueType);

    // Доступ к словарям
    const [refTree, setRefTree] = React.useState<CommonNodeO2[]>([]);
    React.useEffect(() => {
      if (dictVisible) {
        const owner = findNodeOwnerObject(attrNode.key, store.treeData);
        if (owner) {
          setRefTree(
            buildTreeForSrcDict(owner, { dstAttrId: attr.id, typeFilter }),
          );
        } else notification.warning({ message: "Не найден объект" });
      } else {
        setRefTree([]);
      }
    }, [dictVisible]);

    const rolesGroup = store.roleGroups;

    const getStateId = (index: number) => states[index]?.id.toString() ?? "";

    const canCopyPermissions = attrNode.attr.id !== newItemId;
    const objectName = store.curObjectName ?? "";
    const objectId = store.curObjectId;

    const [neighborAttrs, setNeighborAttrs] = React.useState<number[]>([]);
    React.useEffect(() => {
      if (objectId) {
        loadObjectAttrinbutesAll(objectId)
          .then((attrs) =>
            setNeighborAttrs(
              attrs.filter(({ id }) => id !== attr.id).map(({ id }) => id),
            ),
          )
          .catch(onError);
      }
    }, [objectId]);

    if (!objectId) return null;

    return (
      <>
        <AttrCommonFields
          attrKey={attrNode.key}
          attrId={attrNode.attr.id}
          objectId={objectId}
          disabled={disabled}
          dataSource={store}
          isFieldUnique={uniqueAttrTest(store.treeData)}
          loadObjectOptions={() => store.loadObjectOptions()}
          objIteratorMode={objIteratorMode}
        />

        <WidthLimitedFields>
          {/* Переключатель становится виден только если тип текущего атрибута использует словарь */}
          {dictVisible && (
            <FieldsWithTitle title="Дополнительные настройки">
              <VerticalContent>
                <label>
                  <Space style={{ marginBottom: 24 }}>
                    <Form.Item name={edAttrField.useRestriction} noStyle>
                      <Switch disabled={disabled || restrChangeDisabled} />
                    </Form.Item>
                    <span>Ограничения по значению</span>
                  </Space>
                </label>

                {useRestriction && (
                  <>
                    <Form.Item
                      name={edAttrField.masterAttrId}
                      label="Выберите атрибут"
                      rules={[
                        {
                          required: true,
                          message: "Необходимо выбрать атрибут",
                        },
                      ]}
                      className="idMasterAttr"
                    >
                      <SelectAttribute
                        tree={refTree}
                        disabled={disabled || restrChangeDisabled}
                      />
                    </Form.Item>
                    <RestrictionsTableField
                      disabled={disabled || restrChangeDisabled}
                    />
                  </>
                )}
              </VerticalContent>
            </FieldsWithTitle>
          )}

          <FieldsWithTitle title="Права">
            <Collapse
              accordion
              defaultActiveKey={getStateId(0)}
              items={states.map(({ id, name }) => ({
                key: String(id),
                label: `${name} (${id})`,
                forceRender: true,
                children: (
                  <Form.Item label="" name={[edAttrField.rolesMap, String(id)]}>
                    <RolesEditor
                      columns={store.attrTypeActions}
                      rows={rolesGroup}
                      canCopy={canCopyPermissions}
                      stateId={id}
                      store={store}
                    />
                  </Form.Item>
                ),
                extra: canCopyPermissions && (
                  <Flex align="center" gap={12}>
                    <CopyOnStatesButton
                      stateName={name}
                      stateId={id}
                      copyType={PermissionsCopyType.attribute}
                      store={store}
                    />
                    <CopyOnAttsButton
                      stateName={name}
                      stateId={id}
                      objectName={objectName}
                      attributeIds={neighborAttrs}
                    />
                  </Flex>
                ),
              }))}
            />
          </FieldsWithTitle>
        </WidthLimitedFields>
      </>
    );
  },
);

type PropsTypeSelect = {
  types: IdLabel[];
  value?: number;
  onChange?(v: number): void;
  disabled: boolean;
};
const TypeSelect: React.FC<PropsTypeSelect> = (props) => {
  const { types, value, onChange, disabled } = props;
  return (
    <Radio.Group
      disabled={disabled}
      value={value}
      onChange={(e) => {
        onChange?.(e.target.value);
      }}
      className={styles.radioVert}
      options={types.map(({ id, label }) => ({ value: id, label }))}
    />
  );
};
TypeSelect.defaultProps = {
  value: undefined,
  onChange: undefined,
};

const uniqueAttrTest =
  (treeData: CommonNodeO2[]) =>
  (
    field: keyof ZAttribute,
    attrKey: React.Key,
    attrId: number,
    value: unknown,
  ): boolean => {
    const obj = findNodeOwnerObject(attrKey, treeData);
    return !findNode((node: CommonNodeO2) => {
      if (node.type !== "attr" || node.attr.id === attrId || !value)
        return false;
      return node.attr[field] === value;
    }, obj?.children ?? []);
  };

export const AttrFormButtons: React.FC<PropsAttrForm2> = observer(
  ({ store, attrNode }) => (
    <div className={styles.buttons}>
      <UpToObject store={store} />
      <Button
        danger
        icon={<DeleteOutlined />}
        onClick={() => store.safeDeleteCurNode()}
        disabled={!attrNode.actions.delete}
        id="deleteCurAttr"
      >
        Удалить атрибут
      </Button>
    </div>
  ),
);
