import * as React from "react";
import { Checkbox, InputRef } from "antd";
import { CellBox, FnFocus, PropsCellBox } from "./CellBox";

type PropsCellBoxCheckbox = Omit<PropsCellBox<boolean>, "children">;

export const CellBoxCheckbox: React.FC<PropsCellBoxCheckbox> = (props) => (
  <CellBox {...props}>
    {({ value, onChange, onBlur, getFocus }) => (
      <Inner
        value={!!value}
        onChange={onChange}
        onBlur={onBlur}
        getFocus={getFocus}
      />
    )}
  </CellBox>
);

interface PropsInner {
  value: boolean;
  onChange(value: boolean): void;
  onBlur(): void;
  getFocus: (fn: FnFocus) => void;
}

const Inner: React.FC<PropsInner> = (props) => {
  const { value, onChange, onBlur, getFocus } = props;
  const ref = React.useRef<InputRef>(null);
  React.useEffect(() => {
    getFocus(() => {
      ref.current?.focus();
    });
    return () => getFocus(undefined);
  }, [ref.current]);

  return (
    <Checkbox
      ref={ref}
      checked={value}
      onChange={(e) => {
        const { checked } = e.target;
        onChange(checked);
        onBlur();
      }}
    />
  );
};
