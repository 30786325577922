import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import { DrawTab } from "./DrawTab";
import { ZSchedSettingsPlan } from "../../SchedulingTypes";
import { DrawSection } from "./sections/DrawSection";

export const PlanFields: React.FC = () => (
  <DrawTab>
    <DrawSection<ZSchedSettingsPlan>
      tabKey="planSettings"
      objKey="templateObjectId"
      objType="plan"
      objLabel="Объект для формирования плана"
      title="Список обязательных позиций"
      attrs={[
        {
          attrKey: "nameAttributeId",
          attrLabel: "Укажите атрибут названия плана",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: "groupTaskListAttributeId",
          attrLabel: "Укажите атрибут списка групп задач, включенных в план",
          attrType: AttrTypeName.childEntities,
        },
        {
          attrKey: "startDateAttributeId",
          attrLabel: "Укажите атрибут плановой даты начала",
          attrType: AttrTypeName.date,
        },
      ]}
    />
  </DrawTab>
);
