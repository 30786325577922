import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { Button, Form, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { classNames } from "src/common/classNames";
import { FormInstance } from "antd/es/form/Form";
import { CtrlFiltration } from "../TableStore";
import { FiltersFormItem } from "./FiltersFormItem";
import { FilterSettingsButton } from "./FilterSettings/FilterSettingsButton";
import styles from "./FiltersForm.module.less";

/* eslint react/jsx-props-no-spreading: "off" */

export type FilterFieldsDict<TFilters> = Partial<
  Record<keyof TFilters, FiltersFormItem>
>;

export interface PropsFiltersForm<TFilters extends object> {
  store: CtrlFiltration<TFilters>;
  children?: React.ReactNode;
  items?: FilterFieldsDict<TFilters>;
  formClassName?: string;
  preOnChange?: (
    changed: Partial<TFilters>,
    all: TFilters,
    formInstanse: FormInstance<TFilters>,
  ) => void;
}

export const FiltersForm = observer(
  <TFilters extends object>(
    props: PropsFiltersForm<TFilters>,
  ): React.ReactElement => {
    const { store, children, items, formClassName, preOnChange } = props;
    const { filterSettings } = store;
    const [form] = Form.useForm<TFilters>();
    const onChange = async (changed: Partial<TFilters>, all: TFilters) => {
      if (preOnChange) preOnChange(changed, all, form);
      let values: TFilters;
      try {
        values = await form.validateFields();
      } catch (e) {
        if (
          "errorFields" in e &&
          Array.isArray(e.errorFields) &&
          e.errorFields.length === 0
        ) {
          values = e.values;
        } else return;
      }
      const out = { ...values };
      if (items)
        Object.entries(items)
          .filter(([key]) => filterSettings?.isVisible(key) ?? true)
          .forEach(([fieldName, item]) => {
            const itemDef = item as FiltersFormItem;
            if (!itemDef) return;
            const key = fieldName as keyof TFilters;
            const v = values[key] ?? itemDef.defaultValue;
            if (v !== undefined && v !== null) {
              if (itemDef.toSave) {
                out[key] = itemDef.toSave(
                  v,
                  fieldName,
                ) as TFilters[keyof TFilters];
              } else {
                itemDef.save?.(out as Record<string, unknown>, v, fieldName);
              }
            }
          });
      store.setFilters(out);
    };
    const drawItems = () => {
      type Pair = [string, FiltersFormItem];
      const drawItem = ([name, { render, itemProps }]: Pair) => (
        <Form.Item key={name} name={name} {...(itemProps || {})}>
          {render()}
        </Form.Item>
      );
      const fullList = (Object.entries(items ?? {}) as Pair[]).filter(
        ([key, it]) => (filterSettings?.isVisible(key) ?? true) && !!it,
      );
      const mainList = fullList.filter(([, it]) => !it.separated);
      const separated = fullList.filter(([, it]) => it.separated);
      return (
        <div className={styles.itemsBlock}>
          {mainList.length > 0 && (
            <div className={styles.items}>{mainList.map(drawItem)}</div>
          )}
          {separated.length > 0 &&
            separated.map((pair) => <div key={pair[0]}>{drawItem(pair)}</div>)}
        </div>
      );
    };
    const onVisibleChange = () => {
      store.reload();
    };
    // Связываем поля со значениями фильтров. Если они есть, то устанавливаем их в поля. Если нет - то очищаем поля
    React.useEffect(() => {
      if (store.filters) {
        form.setFieldsValue(store.filters);
      } else form.resetFields();
    }, [store.filters]);

    return (
      <Form<TFilters>
        className={classNames([styles.box, formClassName])}
        layout="inline"
        form={form}
        initialValues={store.initialFilters || {}}
        size="middle"
        onValuesChange={onChange}
      >
        {items ? drawItems() : <div className={styles.items}>{children}</div>}
        <Button
          htmlType="reset"
          type="primary"
          icon={
            <Tooltip placement="topLeft" title={t("Clear filters")}>
              <ClearOutlined />
            </Tooltip>
          }
          onClick={(event) => {
            form.resetFields();
            store.setFilters(store.initialFilters);
            event.preventDefault();
            event.stopPropagation();
          }}
        />
        <FilterSettingsButton
          filterItems={items}
          store={filterSettings}
          onChange={onVisibleChange}
        />
      </Form>
    );
  },
);
