import * as React from "react";
import { CellBoxSelect } from "src/components/Sheet/CellBoxSelect";
import { CellBoxText } from "src/components/Sheet/CellBoxText";
import { ImageCarousel } from "src/components/ImageCarousel";
import { CellBox } from "src/components/Sheet/CellBox";
import { t } from "i18next";
import { Bom2Column } from "./Bom2Column";
import { BomSelectAll, BomSelectRow } from "./Bom2Select";
import { Bom2PositionStore } from "../Bom2PositionStore";
import { saveBom2PositionFields } from "../apiBom2Position";
import { BomArticulCell } from "./BomArticulCell";
import { BomSupplierCell } from "./BomSupplierCell/BomSupplierCell";
import { BomColorCell } from "./BomColorCell";
import { textProps } from "./textProps";

/* eslint no-param-reassign: "off" */

export const buildBom2Columns = (store: Bom2PositionStore): Bom2Column[] => [
  {
    key: "select",
    title: <BomSelectAll store={store} />,
    render: (row) => <BomSelectRow store={store} row={row} />,
    minWidth: 36,
  },
  {
    key: "materialCategory",
    title: t("Material"),
    width: "1.2fr",
    minWidth: 160,
    render: ({ id, materialCategory }) => (
      <CellBoxSelect<number | null>
        cellKey={`${id}:materialCategory`}
        store={store.sheetStore}
        value={materialCategory.dictId}
        selectProps={{
          // @ts-ignore
          options: materialCategory.options
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name)),
          fieldNames: { value: "dictId", label: "name" },
          showSearch: true,
          allowClear: true,
          optionFilterProp: "name",
        }}
        save={async (value) => {
          if (!materialCategory.fieldId)
            throw Error("materialCategory.fieldId is null");
          await saveBom2PositionFields(id, [
            {
              id: materialCategory.fieldId,
              value: value ? String(value) : null,
            },
          ]);
          materialCategory.dictId = value ?? null;
          materialCategory.name =
            materialCategory.options.find(({ dictId }) => dictId === value)
              ?.name ?? null;
        }}
      />
    ),
  },
  {
    key: "article",
    title: t("Product article"),
    render: (row, materialTypeId) => (
      <BomArticulCell row={row} store={store} materialTypeId={materialTypeId} />
    ),
    minWidth: 180,
    width: "1fr",
  },
  {
    key: "supplier",
    title: t("Supplier"),
    minWidth: 160,
    width: "1fr",
    render: (row) => <BomSupplierCell row={row} store={store} />,
  },
  {
    key: "image",
    title: t("Image"),
    minWidth: 130,
    width: "1fr",
    render: ({ id, image }) => {
      const { value } = image;
      return (
        <CellBox
          cellKey={`${id}:image`}
          store={store.sheetStore}
          value={value}
          save={async (newValue) => {
            await saveBom2PositionFields(id, [
              { id: image.id, value: newValue || "" },
            ]);
          }}
        >
          {({ value: v, onChange, onBlur }) => (
            <ImageCarousel
              value={v ? [v] : []}
              onChange={(nv) => {
                onChange(!nv?.length ? null : nv[0]!);
                setTimeout(onBlur, 10);
              }}
            />
          )}
        </CellBox>
      );
    },
  },
  {
    key: "location",
    title: t("Location"),
    minWidth: 140,
    width: "1fr",
    render: (row) => (
      <CellBoxText
        cellKey={`${row.id}:location`}
        store={store.sheetStore}
        value={row.location.value}
        textProps={textProps}
        save={async (value) => {
          await saveBom2PositionFields(row.id, [
            { id: row.location.id, value: value || "" },
          ]);
          row.location.value = value ?? null;
        }}
      />
    ),
  },
  {
    key: "qualityDetailsManual",
    title: t("Quality details"),
    minWidth: 200,
    width: "1fr",
    render: (row) => (
      <CellBoxText
        cellKey={`${row.id}:qualityDetailsManual`}
        store={store.sheetStore}
        value={row.qualityDetailsManual.value}
        textProps={textProps}
        save={async (value) => {
          await saveBom2PositionFields(row.id, [
            { id: row.qualityDetailsManual.id, value: value || "" },
          ]);
          row.qualityDetailsManual.value = value ?? null;
        }}
      />
    ),
  },
  {
    key: "size",
    title: t("Trim size"),
    minWidth: 100,
    width: "0.6fr",
    render: (row) => (
      <CellBoxText
        cellKey={`${row.id}.size`}
        store={store.sheetStore}
        value={row.size.value}
        textProps={textProps}
        save={async (value) => {
          await saveBom2PositionFields(row.id, [
            { id: row.size.id, value: value || "" },
          ]);
          row.size.value = value ?? null;
        }}
      />
    ),
  },
  {
    key: "amount",
    title: t("Amount"),
    minWidth: 100,
    width: "0.6fr",
    render: (row) => (
      <CellBoxText
        cellKey={`${row.id}.amount`}
        store={store.sheetStore}
        value={row.amount.value}
        textProps={textProps}
        save={async (value) => {
          await saveBom2PositionFields(row.id, [
            { id: row.amount.id, value: value || "" },
          ]);
          row.amount.value = value ?? null;
        }}
      />
    ),
  },
  ...store.colorColumns.map(
    ({ id, name }) =>
      ({
        key: `${id}:color`,
        title: name,
        minWidth: 120,
        width: "1fr",
        render: (row) => (
          <BomColorCell colorModelId={id} row={row} store={store} />
        ),
      }) satisfies Bom2Column,
  ),
  {
    key: "comment",
    title: t("Comment"),
    minWidth: 120,
    width: "1fr",
    render: (row) => (
      <CellBoxText
        cellKey={`${row.id}.comment`}
        store={store.sheetStore}
        value={row.comment.value}
        textProps={textProps}
        save={async (value) => {
          await saveBom2PositionFields(row.id, [
            { id: row.comment.id, value: value || "" },
          ]);
          row.comment.value = value ?? null;
        }}
      />
    ),
  },
];
