// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__form--YOlzK {
  height: 100%;
  overflow: hidden;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__form--YOlzK .ant-modal-body {
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__content--PT6Gq {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__tableItem--IdJ6a {
  flex: 1;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__tableItem--IdJ6a .ant-form-item-row {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__tableItem--IdJ6a .ant-form-item-control-input,
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__tableItem--IdJ6a .ant-form-item-control-input-content {
  height: 100%;
  overflow: hidden;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__footer--IunY0 {
  height: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Summary/NewBomModal.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;AACJ;AAHA;EAIQ,aAAA;EACA,sBAAA;AAER;AACA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAEA;EACI,OAAA;AAAJ;AADA;EAIQ,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AAAR;AAPA;;EAYQ,YAAA;EACA,gBAAA;AADR;AAIA;EACI,YAAA;AAFJ","sourcesContent":[".form {\n    height: 100%;\n    overflow: hidden;\n    :global(.ant-modal-body) {\n        display: flex;\n        flex-direction: column;\n    }\n}\n.content {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n}\n\n.tableItem {\n    flex: 1;\n    // Чтобы таблица заняла всю высоту нужно все контейнеры сделать 100% по высоте:\n    :global(.ant-form-item-row) {\n        height: 100%;\n        overflow: hidden;\n        display: flex;\n        flex-direction: column;\n    }\n    // :global(.ant-form-item-row),\n    :global(.ant-form-item-control-input),\n    :global(.ant-form-item-control-input-content) {\n        height: 100%;\n        overflow: hidden;\n    }\n}\n.footer {\n    height: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__form--YOlzK`,
	"content": `src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__content--PT6Gq`,
	"tableItem": `src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__tableItem--IdJ6a`,
	"footer": `src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-NewBomModal-module__footer--IunY0`
};
export default ___CSS_LOADER_EXPORT___;
