import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { Select } from "antd";
import { TableStore } from "src/components/tables/TableStore";
import { ScrollableTable } from "src/components/ScrollableTable";
import { AColumn } from "src/components/tables/AsyncTable";
import { idNameFieldNames, ZIdName } from "src/types/ZIdName";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import {
  FilterFieldsDict,
  filterItem,
} from "src/components/tables/FiltersForm";
import { filterMonoSearch } from "src/components/tables/FiltersForm/filterMonoSearch";
import { PointOpt } from "../MChartTable/PointDict";

export interface FilterMcPoints {
  objectId?: number;
  query?: string;
  categoryId?: number;
}

interface PropsPointSelectModal {
  title: string;
  tableStore: TableStore<PointOpt, FilterMcPoints> | null;
  categoryList: ZIdName[];
  success: (rows: PointOpt[]) => void;
  close: () => void;
}

export const PointSelectModal: React.FC<PropsPointSelectModal> = observer(
  (props) => {
    const { title, tableStore, categoryList, success, close } = props;
    const filters: FilterFieldsDict<FilterMcPoints> = {
      query: filterMonoSearch(),
      categoryId: filterItem(
        Select,
        {
          placeholder: t("Category"),
          options: categoryList
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name)),
          fieldNames: idNameFieldNames,
          allowClear: true,
          showSearch: true,
          optionFilterProp: "name",
        },
        { style: { flex: 1 } },
      ),
    };

    const columns: AColumn<PointOpt>[] = React.useMemo(
      () => [
        { key: "label", dataIndex: "label", title: t("Point name") },
        { key: "desc", dataIndex: "desc", title: t("Description") },
        { key: "category", dataIndex: "category", title: t("Category") },
      ],
      [],
    );

    return (
      <ModalVertFixed
        title={title}
        height="80vh"
        open={!!tableStore}
        onCancel={close}
        width="700px"
        centered
        onOk={() => {
          success(tableStore?.selected ?? []);
          close();
        }}
      >
        {tableStore && (
          <ScrollableTable
            store={tableStore}
            columns={columns}
            filterItems={filters}
            onRowClick={(row) => {
              selectOnRowClick(row, tableStore);
            }}
          />
        )}
      </ModalVertFixed>
    );
  },
);
