import * as React from "react";

import { Input, InputRef } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { CellBox, FnFocus, PropsCellBox } from "./CellBox";

type InputValue = string | null | undefined;

type PropsCellBoxText<TValue extends InputValue> = Omit<
  PropsCellBox<TValue>,
  "children"
> & {
  textProps?: Omit<TextAreaProps, "onBlur" | "onChange" | "value">;
};

export const CellBoxText = <TValue extends InputValue>(
  props: PropsCellBoxText<TValue>,
) => {
  const { textProps, ...cellProps } = props;
  return (
    <CellBox<TValue> {...cellProps} disableEnter>
      {(chProps) => <CellBoxTextChildren {...chProps} textProps={textProps} />}
    </CellBox>
  );
};
CellBoxText.defaultProps = {
  textProps: undefined,
};

type PropsCellBoxTextChildren<TValue extends InputValue> = {
  value: TValue | undefined;
  onChange: (v: TValue) => void;
  onBlur: () => void;
  textProps: PropsCellBoxText<TValue>["textProps"];
  getFocus: (fn: FnFocus) => void;
};

const CellBoxTextChildren = <TValue extends InputValue>(
  props: PropsCellBoxTextChildren<TValue>,
) => {
  const { textProps, value, onChange, onBlur, getFocus } = props;
  const ref = React.useRef<InputRef>(null);
  React.useEffect(() => {
    getFocus(() => {
      ref.current?.focus();
    });
    return () => getFocus(undefined);
  }, [ref.current]);
  return (
    <Input.TextArea
      ref={ref}
      // variant="borderless"
      {...textProps}
      value={value as TextAreaProps["value"]}
      onChange={(e) => onChange(e.currentTarget.value as TValue)}
      onBlur={onBlur}
    />
  );
};
