import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import { DrawTab } from "./DrawTab";
import { ZSchedSettingsPlanTemplate } from "../../SchedulingTypes";
import { DrawSection } from "./sections/DrawSection";

export const PlanTemplateFields: React.FC = () => (
  <DrawTab>
    <DrawSection<ZSchedSettingsPlanTemplate>
      tabKey="planTemplateSettings"
      objKey="templateObjectId"
      objType="planTemplate"
      objLabel="Объект для формирования шаблона плана"
      title="Список обязательных позиций"
      attrs={[
        {
          attrKey: "nameAttributeId",
          attrLabel: "Укажите атрибут названия плана",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: "groupTaskListAttributeId",
          attrLabel: "Укажите атрибут списка групп задач, включенных в план",
          attrType: AttrTypeName.childEntities,
        },
      ]}
    />
  </DrawTab>
);
