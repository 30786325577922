import { makeAutoObservable } from "mobx";
import { makeFieldOptions } from "src/common/makeFieldOptions";
import { onError } from "src/common/onError";
import {
  loadObjects,
  loadObjectAttrinbutesAll,
} from "src/pages/ManagementPage/objectsApi";
import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import { ZObjectItem } from "src/types/ZObjectItem";

export type TechpackObjType = "model" | "supplier" | "techpack";

export const techpackSettingsStore = makeAutoObservable({
  async init(attrTypesDict: Record<number, string>, objectId: number) {
    try {
      this.setLoading(true);
      const [objects] = await Promise.all([
        loadObjects(),
        this.loadAttrs("model", objectId),
      ]);
      this.setAttrTypesDict(attrTypesDict);
      this.setObjects(objects);
    } catch (e) {
      onError(e);
    } finally {
      this.setLoading(false);
    }
  },
  loading: false,
  setLoading(value: boolean) {
    this.loading = value;
  },
  objects: [] as ZObjectItem[],
  setObjects(list: ZObjectItem[]) {
    this.objects = list;
  },
  attrsMap: {} as Record<TechpackObjType, ZAttribute[]>,
  setAttrs(key: TechpackObjType, list: ZAttribute[]) {
    this.attrsMap[key] = list;
  },
  loadingAttrs: new Set<TechpackObjType>(),
  setLoadingAttrs(type: TechpackObjType) {
    this.loadingAttrs.add(type);
  },
  unsetLoadingAttrs(type: TechpackObjType) {
    this.loadingAttrs.delete(type);
  },
  attrTypesDict: {} as Record<number, string>,
  setAttrTypesDict(dict: Record<number, string>) {
    this.attrTypesDict = dict;
  },
  makeOptionsByType(typeName: AttrTypeName, attrs: ZAttribute[]) {
    return makeFieldOptions(
      attrs.filter(
        ({ valueType }) => this.attrTypesDict[valueType] === typeName,
      ),
    );
  },
  async loadAttrs(key: TechpackObjType, objId: number | undefined) {
    try {
      this.setLoadingAttrs(key);
      this.setAttrs(key, []);
      if (objId) this.setAttrs(key, await loadObjectAttrinbutesAll(objId));
    } catch (e) {
      onError(e);
    } finally {
      this.unsetLoadingAttrs(key);
    }
  },
});

export type SchedulingSettingsStore = typeof techpackSettingsStore;
