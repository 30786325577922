import { FileImageFilled, RetweetOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { getEditorInfo } from "src/common/attrEdit";
import { getViewInfo } from "src/common/attrView";
import { getComponentEditor } from "src/pages/EntityCardPage/blockBuilder/createItem2";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import React, { Fragment } from "react";
import { loadRolesDictCached } from "src/common/apiRoles";
import { apiLoadPersonList } from "src/common/apiPerson";
import { AttrTypeName } from "src/types/AttrType";
import { composeEntityLabel } from "../../composeEntityLabel";
import { LabelComposeConfig } from "../../getActualComposeView";

export const getPersonRefAttrLabel = async (
  person: ZEntity,
  attribute: ZAttribute,
  personIdList: string[],
  typeMap: Record<number, string>,
  // для отслеживания рекурсии
  pathRegister: number[],
  config?: LabelComposeConfig,
) => {
  if (pathRegister.includes(attribute.id))
    return [
      <Tag key={attribute.id} color="red">
        <RetweetOutlined
          title={`Рекурсивная зависимость по атрибуту "${attribute.name}"`}
        />
      </Tag>,
    ];
  pathRegister.push(attribute.id);
  const editorInfo = getEditorInfo(attribute.viewStyles);
  const component = getComponentEditor(editorInfo, typeMap, attribute);
  const viewInfo = getViewInfo(attribute.viewType);

  if (component?.editor === "PersonSelect" && viewInfo) {
    const persons = await apiLoadPersonList({
      roleIds: attribute.roleIds ?? [],
      userIds: personIdList,
    });
    const rolesDict = await loadRolesDictCached();
    const labelsProm = persons.map((p) => {
      const [roleId] = p.roleIds;
      const role = rolesDict[Number(roleId)];
      const viewAtts = component.rolesViewAtts?.[String(role?.id)] ?? [];
      return composeEntityLabel(
        p,
        viewAtts,
        viewInfo,
        undefined,
        // Если config не задан явно, необходимо его определить в стандартном виде
        config || {
          separator: "comma",
          replace: { [AttrTypeName.image]: <FileImageFilled /> },
        },
      );
    });
    // Необходимо разделить элементы пробелом, чтобы они не "слипались"
    const nestedLabels = (await Promise.all(labelsProm)).reduce<
      React.ReactNode[]
    >((acc, label, index) => {
      if (index > 0) acc.push(" ");
      acc.push(label);
      return acc;
    }, []);
    return nestedLabels.flat();
  }

  return [<Fragment key={person.id}>{person.id}</Fragment>];
};
