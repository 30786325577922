export default {
  translation: {
    "Access denied": "Доступ запрещен",
    "Action is irreversible": "Это действие необратимо",
    "Actual start date": "Факт. дата начала",
    "Actual end date": "Факт. дата окончания",
    Add: "Добавить",
    "Add points...": "Добавить точки...",
    "Add version": "Добавить версию",
    Address: "Адрес",
    "Advanced settings": "Дополнительные настройки",
    all: "все",
    "All attributes": "Все атрибуты",
    "All data will be deleted": "Все данные будут удалены",
    "Appeal to technical support is registered":
      "Обращение в техподдержку зарегистрировано",
    Amount: "Количество",
    Apply: "Применить",
    "Are you sure to delete {{count}} persons?":
      "Удалить {{count}} пользователей?",
    "Are you sure to delete {{count}} persons?_one":
      "Удалить {{count}} пользователя?",
    "Are you sure to delete version?": "Вы уверены, что удаляете версию?",
    "Are you sure to delete selected positions?":
      "Вы уверены, что хотите удалить выбранные позиции?",
    "Attached files": "Вложенные файлы",
    "Attention!": "Внимание!",
    Attributes: "Атрибуты",
    'Attributes to display the role "{{role}}"':
      'Атрибуты для отображения роли "{{role}}"',
    "Authorization required": "Требуется авторизация",
    "Base size": "Базовый размер",
    "Bussiness dictionaries": "Бизнес-справочники",
    Category: "Категория",
    Cancel: "Отмена",
    "Cannot select the current model to copy":
      "Невозможно выбрать текущую модель для копирования",
    "Change of state": "Смена состояния",
    "Change password": "Изменение пароля",
    ChangingTheStateOfAnInstance_one: "Изменение состояния экземпляра",
    ChangingTheStateOfAnInstance_many: "Изменение состояния экземпляров",
    "Chat list": "Список чатов",
    "Clear filters": "Очистить фильтры",
    "Click or drag the file/s into the area to upload":
      "Нажмите или перетащите файл/ы в область для загрузки",
    Close: "Закрыть",
    "Collapse menu": "Свернуть меню",
    "Columns visibility": "Видимость столбцов",
    Comment: "Комментарий",
    "Communication between objects is not configured. Contact your administrator.":
      "Не настроена связь между объектами. Обратитесь к администратору.",
    "Contacting tech support": "Обращение в техподдержку",
    "Copied instances": "Копируемые экземпляры",
    Copy: "Копировать",
    "Copy to": "Копировать В...",
    "Copy to another instance from current instance":
      "Копирование В другой экземпляр ИЗ текущего",
    "Copy from": "Копировать ИЗ...",
    "Copy from another instance to the current instance":
      "Копирование ИЗ другого экземпляра В текущий",
    "Copying an instance": "Копирование экземпляра",
    "Copy from model": "Копировать из модели",
    "Copy from template": "Копировать из шаблона",
    "Copy source": "Источник копирования",
    "Copy to another model": "Скопировать в другую модель",
    Count: "Количество",
    Country: "Страна",
    Create: "Создать",
    "Create a chat": "Создать чат",
    "Create a task": "Создать задачу",
    "Create empty matType": "Создать пустую позицию {{matType}}",
    "Create from template": "Создать из шаблона",
    "Create matType from library": "Создать позицию {{matType}} из справочника",
    "Current password": "Текущий пароль",
    "Current state": "Текущее состояние",
    Dashboards: "Дашборды",
    "Data not loaded": "Данные не загружены",
    "Data updated": "Выполнено обновление данных",
    "Data will be displayed after saving":
      "Данные отобразятся после сохранения",
    DateFormat: "DD.MM.YYYY",
    DateTimeFormat: "DD.MM.YYYY HH:mm",
    "Delay / override": "Задержка / опережение",
    Delete: "Удалить",
    "Delete chat": "Удалить чат",
    "Delete selected": "Удалить выбранное",
    "Delete selected tasks?": "Удалить выбраные задачи?",
    "Delete version": "Удалить версию",
    "Deletion is impossible": "Удаление невозможно",
    "Describe the question": "Опишите вопрос",
    Description: "Описание",
    Dictionaries: "Справочники",
    "Display available only for created instances":
      "Отображение доступно только для созданных экземпляров",
    "Do you really want to delete the chat ?":
      "Вы действительно хотите удалить чат",
    "Do you really want to delete the selected instances?":
      "Вы действительно хотите удалить выбранные экземпляры?",
    "Do you want to delete the current entry?":
      "Выполнить удаление текущей записи?",
    "Doesn't match": "Не совпадает",
    "Do you want to delete the selected records?":
      "Выполнить удаление выделенных записей?",
    Duration: "Длительность",
    Edit: "Редактировать",
    "Enter a name": "Введите имя",
    "Enter email": "Введите email",
    "Enter the Email you used when registering your account":
      "Введите Email, который вы использовали при регистрации учетной записи. Мы отправим вам ссылку для восстановления пароля.",
    "Enter information to register on the platform":
      "Внесите данные для регистрации на платформе",
    "EntityId value not received": "Не получено значение entityId",
    Entity_one: "Сущность",
    Entity_few: "Сущности",
    Error: "Ошибка",
    "Error when accessing the server": "Ошибка при обращении к серверу",
    "Estimated start date": "Расчетная дата начала",
    "Estimated end date": "Расчетная дата окончания",
    "Executed instance creation": "Выполнено создание экземпляра",
    "Find an attribute": "Найти атрибут",
    Finish: "Завершить",
    "Format: value": "Формат: {{value}}",
    From: "От",
    "Full name": "Ф.И.О.",
    "General information": "Общая информация",
    "Groups and roles": "Группы и роли",
    Heading: "Название",
    "Hierarchy structure": "Структура иерархии",
    "History of changes": "История изменений",
    Image: "Изображение",
    "Image modification": "Изменение изображений",
    "Incorrect current password": "Указан неверный текущий пароль",
    "Incorrect format of received data": "Ошибочный формат полученных данных",
    "Instance of object": "Экземпляр объекта {{name}}",
    "Instructions for working in the system": "Инструкция по работе в системе",
    "Invalid email format": "Неверный формат email",
    "Invalid data format:": "Неверный формат данных для {{file}}",
    "Invalid page URL": "Неверный URL-адрес страницы",
    "Invalid row id in version key":
      "Неверный идентификатор строки в ключе версии: {{key}}",
    "Invalid version key": "Неверный ключ версии: {{key}}",
    "Is not selected": "{{parameter}} не выбран",
    "It is required to correctly fill in the fields on the form":
      "Требуется правильно заполнить поля на форме",
    "Letter has been sent": "Письмо отправлено",
    Loading: "Загрузка",
    Location: "Расположение",
    Login: "Вход",
    Logout: "Выйти",
    Members: "Участники",
    Message: "Сообщение",
    Main: "Главное",
    "Main title": "Главная",
    Management: "Управление",
    "Mark everything as read": "Отметить всё прочитанным",
    "Marked by reading": "Отметить прочитанным",
    Material: "Материал",
    "Maximum number: count": "Максимальное количество: {{count}}",
    "Maximum total size:": "Максимальный общий размер: {{size}} Мб",
    "Menu settings": "Настройка меню",
    "Missing value": "Отсутствует значение {{parameter}}",
    Models: "Модели",
    Modify: "Изменить",
    Name: "Имя",
    "New instance": "Новый экземпляр",
    "New instance has been created": "Создан новый экземпляр",
    "New instance of object": "Новый экземпляр объекта {{name}}",
    "New instances were created": "Создано {{count}} новых экземпляров",
    "New instances were created_one": "Создан {{count}} новый экземпляр",
    "New instances were created_few": "Создано {{count}} новых экземпляра",
    "New password": "Новый пароль",
    "New position": "Новая позиция",
    "New task": "Новая задача",
    "Next state": "Следующее состояние",
    "No available states for transition":
      "Нет доступных состояний для перехода",
    "No match for path {path}": "Нет соответствия пути {path}",
    "No object specified": "Не указан объект",
    Notifications: "Уведомления",
    "Number of hidden columns": "Количество скрытых колонок: {{count}}",
    "Objects and attributes": "Объекты и атрибуты",
    Or: "Или",
    Overwrite: "Перезаписать",
    "Overwrite measurement chart changes?":
      "Перезаписать изменения Measurement chart?",
    Password: "Пароль",
    "Password change completed": "Выполнена смена пароля",
    "Password must contain": "Пароль должен содержать",
    "Password recovery": "Восстановление пароля",
    Persons: "Пользователи",
    Plan: "План",
    "Plan template": "Шаблон плана",
    "Planned start date": "Плановая дата начала",
    "Planned end date": "Плановая дата окончания",
    "Please, select a source model and click the Copy button.":
      "Пожалуйста, выберите исходную модель и нажмите кнопку Копировать..",
    "Please, select a template instance and click the Copy button.":
      "Выберите экземпляр шаблона и нажмите кнопку Копировать.",
    "Point name": "Название точки",
    "Press Ctrl+Enter/Cmd+Enter to apply":
      "Нажмите Ctrl+Enter/Cmd+Enter, чтобы применить",
    "Previous task": "Предыдущая задача",
    "Product article": "Артикул",
    "Quality details": "Детали",
    Question: "Вопрос",
    "Reference is empty": "{{object}} reference is empty",
    Refresh: "Обновить",
    Register: "Зарегистрироваться",
    "Registration of object": 'Регистрация объекта "{{obj}}"',
    Repeat: "Повторить",
    "Repeat new password": "Повтор нового пароля",
    Reset: "Сбросить",
    "Restore password": "Восстановить пароль",
    "Return to the authorization page": "Вернуться на страницу авторизации",
    Save: "Сохранить",
    "Saving is not possible. Status: {{status}}":
      "Сохранение невозможно. Статус: {{status}}",
    Scale: "Скейлинг",
    "Scaling will change the dimension values relative to the base size.":
      "При скейлинге значения размеров изменяются относительно базового размера.",
    Search: "Поиск",
    "Search by name or description": "Поиск по имени или описанию",
    Send: "Отправить",
    "Send link": "Отправить ссылку",
    "Server error": "Ошибка сервера",
    Setting: "Настройки",
    Select: "Выбрать",
    "Select dependent objects to copy":
      "Выберите зависимые объекты для копирования:",
    "Select points to create": "Выберите точки для создания",
    "Select the instance to which you want to copy the previously selected instances of the linked object with all relationships":
      "Выберете экземпляр, в который необходимо скопировать ранее выбранные экземпляры связанного объекта со всеми связями",
    "Selected entities": "Выбранные сущности",
    "Selected lines count": "Выбрано {{count}} строк",
    "Selected lines count_one": "Выбрана {{count}} строка",
    "Selected lines count_few": "Выбрано {{count}} строки",
    "Selected materials": "Выбранные материалы",
    "Selected material": "Выбранный материал",
    "Select the desired menu item to start operation":
      "Выберите нужный пункт в меню для начала работы",
    "Select the instance from which you want to copy the instances of the linked object with all the relationships":
      "Выберете экземпляр, из которого необходимо скопировать экземпляры связанного объекта со всеми связями",
    "Select the instances of the linked object that you want to copy to the current instance":
      "Выберете экземпляры связанного объекта, которые необходимо скопировать в текущий экземпляр",
    "Selecting a point": "Выбор точки",
    "Send message": "Отправить сообщение",
    "Send registration request": "Отправить запрос на регистрацию",
    "Short name": "Краткое название",
    "Sign In": "Войти",
    Size: "Размер",
    "Size line": "Размерный ряд",
    "Size scale": "Размерный ряд",
    "Show for the period": "Показать за период",
    "Specify the topic": "Укажите тему",
    "SSO Authorization": "Авторизация через SSO",
    State: "Состояние",
    "State change performed": "Выполнено изменение состояния",
    Status: "Статус",
    Subject: "Тема",
    Submit: "Отправить",
    Summary: "Сводка",
    Supplier: "Поставщик",
    "System is not ready": "Система не готова",
    "Table export": " Экспорт таблицы",
    Task: "Задача",
    "Task group": "Группа задач",
    "Task group / task": "Группа задач / задача",
    "Task group template": "Шаблон группы задач",
    "Task list": "Список задач",
    "Task template": "Шаблон задачи",
    "Tech support": "Техподдержка",
    templates: "шаблоны",
    "The field is mandatory": "Поле является обязательным",
    "The instance is missing": "Экземпляр отсутствует",
    "The instances have been deleted": "Экземпляры были удалены",
    "The link must start with":
      "Ссылка должна начинаться с http:// или https://",
    "The measurement chart has been copied successfully":
      "Measurement chart успешно скопирован",
    "The state has been deleted": "Состояние было удалено",
    "The service has not been configured": "Не выполнена настройка сервиса",
    "The dictionary must contain at least one entry":
      "Словарь {{dict}} должен содержать хотя бы одну запись",
    "There are no messages in the chat": "Cообщения в чате отсутствуют",
    "There is no user with the specified data":
      "Нет пользователя с указанными данными",
    "This file format is not supported":
      "Данный формат файла не поддерживается",
    TimeFormat: "HH:mm:ss",
    To: "До",
    "To home": "На главную",
    Total: "Итог",
    "Total files size exceeds": "Общий размер файлов превышает {{size}} Мб",
    "Total models: {{count}}": "Всего моделей: {{count}}",
    "Total information": "Суммарная информация",
    "Total instances count": "Всего экземпляров: {{count}}",
    "Total persons count": "Всего {{count}} пользователей",
    "Total persons count_one": "Всего {{count}} пользователь",
    "Total persons count_few": "Всего {{count}} пользователя",
    "Trim size": "Размер",
    Upload: "Загрузить",
    "Upload a file": "Прикрепить файл",
    User: "Пользователь",
    UserAccount: "Личный кабинет",
    "Your request has been registered by the technical support service":
      "Ваше обращение зарегистрировано службой технической поддержки",
    "Wait until saving finishes": "Дождитесь завершения сохранения",
    "Welcome to the Omnidata platform!":
      "Добро пожаловать на платформу Omnidata!",
    "When saving, multiple instances of the object will be created":
      'При сохранении будет создано несколько экземпляров объекта "{{obj}}", по одному на каждое выбранное значение атрибута "{{attr}}"',

    actionType: {
      // see ActionTypeName
      ATTRIBUTE: "Атрибут",
      OBJECT: "Объект",
      ENTITY: "Экземпляр",
    },
    attrType: {
      ARTICLE: "Артикул",
      BOOLEAN: "Логическое значение",
      CHILD_OBJECT_ID: "Список зависимых объектов",
      DATE: "Дата",
      DATE_WITH_TIME: "Дата и время",
      OBJECT_ID: "Ссылка на другой объект",
      ONE_CHOICE_DICTIONARY: "Справочник",
      FILE: "Файл",
      FORMULA: "Формула",
      IMAGE: "Изображение",
      INT: "Целое число",
      LINK_TO_EXTERNAL_SOURCE: "Ссылка на внешний ресурс",
      LINKED_VALUE: "Отображение по ссылке",
      MULTIPLE_CHOICE_DICTIONARY: "Множественный выбор из справочника",
      NUMERIC: "Дробное число",
      STRING: "Текст",
      SYSTEM_LANGUAGE: "Язык системы",
      TIME: "Время",
      USER_FILTER: "Список пользователей",
    },
    groupType: {
      ByDictionary: "На основе справочника",
      Mnemonic: "Мнемоническая",
      Dictionary: "Справочник",
    },
    management: {
      AddGroup: "Добавить группу",
      "Confirm deletion of dictionary group":
        "Вы действительно хотите удалить группу справочников?",
      "Confirm deletion of dictionary":
        "Вы действительно хотите удалить справочник?",
      "Deleting a dictionary group": "Удаление группы справочников",
      "Group name": "Название группы",
      "New group of dictionaries": "Новая группа справочников",
      "Wrong group type": "Неверный формат группы",
    },
    Pages: {
      Plans: "Планы",
      Projects: "Проекты",
      Reports: "Отчёты",
    },
    PasswordProps: {
      "at least N characters": "не менее {{count}} символов",
      "contain numbers": "содержать цифры {{n}}",
      "uppercase Latin letters": "прописные латинские буквы {{n}}",
      "lowercase Latin letters": "строчные латинские буквы {{n}}",
      "special characters": "специальные символы",
      "password cannot be the same as login":
        "пароль не может совпадать с логином",
      "Not done": "Не выполнено: {{n}}",
    },
    vaidation: {
      required: "Поле обязательно для заполнения",
    },
  },
};
