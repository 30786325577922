// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomSupplierCell-BomSupplierCell-module__box--ybqDP {
  display: flex;
  gap: 3px;
  height: 100%;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomSupplierCell-BomSupplierCell-module__edit--VhmMY {
  flex: 1;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomSupplierCell-BomSupplierCell-module__tableBox--Ak8ho {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Position/Bom2Table/BomSupplierCell/BomSupplierCell.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,QAAA;EACA,YAAA;AACJ;AACA;EACI,OAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,YAAA;EACA,gBAAA;AACJ","sourcesContent":[".box {\n    display: flex;\n    gap: 3px;\n    height: 100%;\n}\n.edit {\n    flex: 1;\n}\n.tableBox {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n    height: 100%;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomSupplierCell-BomSupplierCell-module__box--ybqDP`,
	"edit": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomSupplierCell-BomSupplierCell-module__edit--VhmMY`,
	"tableBox": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomSupplierCell-BomSupplierCell-module__tableBox--Ak8ho`
};
export default ___CSS_LOADER_EXPORT___;
