import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import { DrawTab } from "./DrawTab";
import { DrawSection } from "./sections/DrawSection";
import {
  ZSchedSettingsGroup,
  ZSchedulingSettings,
} from "../../SchedulingTypes";
import { SchedulingObjType } from "../SchedulingSettingsStore";

interface PropsGroupFields {
  tabKey: keyof ZSchedulingSettings;
  objType: SchedulingObjType;
}

export const GroupFields: React.FC<PropsGroupFields> = ({
  tabKey,
  objType,
}) => (
  <DrawTab>
    <DrawSection<ZSchedSettingsGroup>
      tabKey={tabKey}
      objKey="templateObjectId"
      objType={objType}
      objLabel="Объект для формирования шаблона группы задач"
      title="Список обязательных позиций"
      attrs={[
        {
          attrKey: "nameAttributeId",
          attrLabel: "Укажите атрибут названия группы задач",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: "relationPlanTemplateAttributeId",
          attrLabel: "Укажите атрибут принадлежности к шаблону плана",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: "taskListAttributeId",
          attrLabel: "Укажите атрибут списка задач, включенных в группу задач",
          attrType: AttrTypeName.childEntities,
        },
      ]}
    />
  </DrawTab>
);
