// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-McImageView-McImageView-module__imgViewBox--Fg9k2 {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  min-width: min-content;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-McImageView-McImageView-module__drag--UgC02 {
  width: 8px;
  background: #F0F0F0;
  cursor: col-resize;
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-McImageView-McImageView-module__dragCenter--foEkx {
  width: 4px;
  height: 50%;
  max-height: 56px;
  border-radius: 4px;
  background: #BFBFBF;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-McImageView-McImageView-module__body--CxQP1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-McImageView-McImageView-module__body--CxQP1 > * {
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/McImageView/McImageView.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AACJ;AACA;EACI,UAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACA;EACI,UAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AACA;EACI,OAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAAI;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".imgViewBox {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: row;\n    min-width: min-content;\n}\n.drag {\n    width: 8px;\n    background: #F0F0F0;\n    cursor: col-resize;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.dragCenter {\n    width: 4px;\n    height: 50%;\n    max-height: 56px;\n    border-radius: 4px;\n    background: #BFBFBF;\n}\n.body {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 8px;\n    & > * {\n    max-width: 100%;\n    max-height: 100%;\n    overflow-y: auto;\n    overflow-x: hidden;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgViewBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-McImageView-McImageView-module__imgViewBox--Fg9k2`,
	"drag": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-McImageView-McImageView-module__drag--UgC02`,
	"dragCenter": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-McImageView-McImageView-module__dragCenter--foEkx`,
	"body": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-McImageView-McImageView-module__body--CxQP1`
};
export default ___CSS_LOADER_EXPORT___;
