import * as React from "react";
import { observer } from "mobx-react-lite";
import { Select } from "antd";
import { CtrlSelection } from "../TableStore";

// Есть похожий компонент SelectedTags, но там нет общей очистки. И при большом количестве тегов будет выглядеть не очень.

interface PropsKeepSelected<Row extends {}> {
  store: CtrlSelection<Row>;
  makeTag(row: Row): React.ReactNode;
  placeholder?: React.ReactNode;
}

export const KeepSelected = observer(
  <Row extends {}>(props: PropsKeepSelected<Row>) => {
    const { store, makeTag, placeholder } = props;
    const { rowKey } = store;
    const options = store.selected.map((row) => ({
      value: row[rowKey],
      label: makeTag(row),
    }));
    const onChange = (keys: unknown[]) => {
      const dstRows: Row[] = [];
      keys.forEach((dstKey) => {
        const dstRow = store.selected.find(
          (srcRow) => srcRow[rowKey] === dstKey,
        );
        if (dstRow) dstRows.push(dstRow);
      });
      store.setSelected(dstRows);
    };
    return (
      <Select
        allowClear
        placeholder={placeholder}
        mode="tags"
        maxTagCount="responsive"
        options={options}
        value={store.selected.map((row) => row[rowKey])}
        onChange={onChange}
      />
    );
  },
);
