import * as React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { rest } from "src/common/rest";
import { urlBom2 } from "src/businessServices/services/bsBom2/apiBom2";
import { CellBoxText } from "src/components/Sheet/CellBoxText";
import { EdBomRow } from "../../ZBom2Position";
import { Bom2PositionStore } from "../../Bom2PositionStore";
import { saveBom2PositionFields } from "../../apiBom2Position";
import { SelectBomMaterial } from "../../SelectBomMaterial";
import { ZBom2Material } from "../../ZBom2Material";
import { textProps } from "../textProps";
import styles from "./BomArticulCell.module.less";

interface PropsBomArticulCell {
  row: EdBomRow;
  materialTypeId: number;
  store: Bom2PositionStore;
}

export const BomArticulCell: React.FC<PropsBomArticulCell> = (props) => {
  const { row, store, materialTypeId } = props;
  const [open, setOpen] = React.useState(false);
  const onChange = async (mat: ZBom2Material) => {
    await rest.post(urlBom2("/bom-position/assign-material"), undefined, {
      params: {
        bomPositionId: row.id,
        materialId: mat.id,
      },
    });
    await saveBom2PositionFields(row.id, [
      { id: row.article.name.id, value: mat.name },
      { id: row.article.article.id, value: mat.article },
    ]);
    await store.reload();
  };
  return (
    <div className={styles.box}>
      <div className={styles.edit}>
        <CellBoxText<string | null>
          cellKey={`${row.id}:manualValue`}
          store={store.sheetStore}
          textProps={textProps}
          value={row.articleManualValue.value}
          save={async (value: string | null | undefined) => {
            await saveBom2PositionFields(row.id, [
              { id: row.articleManualValue.id, value: value ?? null },
            ]);
          }}
        />
      </div>
      <Button
        icon={<PlusOutlined />}
        size="small"
        type="text"
        onClick={() => setOpen(true)}
      />
      <SelectBomMaterial
        materialTypeId={materialTypeId}
        multi={false}
        open={open}
        close={() => setOpen(false)}
        exec={async (list) => {
          const item = list[0];
          if (item) {
            await onChange(item);
          }
        }}
      />
    </div>
  );
};
