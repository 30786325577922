import * as React from "react";
import { CtrlResizable } from "src/components/tables/TableStore";
import { ColumnPoor } from "src/components/tables/ColumnPoor";
import { observer } from "mobx-react-lite";
import styles from "./ResizerStd.module.less";
import { getColumnWidth } from "../templates/getColumnWidths";

type PropsResizerStd = {
  column: ColumnPoor;
  store: CtrlResizable;
  containerRef?: React.RefObject<HTMLDivElement>;
};

export const ResizerStd: React.FC<PropsResizerStd> = observer((props) => {
  const { column, store, containerRef } = props;

  React.useEffect(() => {
    // Устанавливаем ширину только если она ещё не задана
    if (!store.columnWidths[column.key] && containerRef?.current) {
      store.setColumnWidth(column.key, containerRef.current.offsetWidth);
    }
  }, [containerRef?.current]);

  const resizeColumn =
    (key: string) => (event: React.MouseEvent<HTMLDivElement>) => {
      const startX = event.clientX;
      const colWRaw = getColumnWidth(store.columnWidths, column.key);
      const colW = colWRaw ? Number(colWRaw) : 0;
      const handleMouseMove = (e: MouseEvent) => {
        const currentWidth = colW + (e.clientX - startX);
        store.setColumnWidth(key, currentWidth);
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

  return (
    store.resizableColumns && (
      <div
        className={styles.columnResizeHandle}
        onMouseDown={resizeColumn(column.key)}
        role="presentation"
      />
    )
  );
});
