import React from "react";
import { Flex, Form, Select } from "antd";
import { observer } from "mobx-react-lite";
import { ZSchedulingSettings } from "src/businessServices/services/bsScheduling/SchedulingTypes";
import { AttrTypeName } from "src/types/AttrType";
import { classNames } from "src/common/classNames";
import { makeFieldOptions } from "src/common/makeFieldOptions";
import {
  SchedulingObjType,
  schedulingSettingsStore,
} from "../../../SchedulingSettingsStore";
import styles from "./DrawSection.module.less";

interface AttrDef<TTabFields extends {}> {
  attrKey: keyof TTabFields;
  attrLabel: string;
  attrType?: AttrTypeName;
}

interface PropsDrawSection<TTabFields extends {}> {
  tabKey: keyof ZSchedulingSettings;
  objKey: keyof TTabFields;
  objType: SchedulingObjType;
  attrs: AttrDef<TTabFields>[];
  objLabel?: string;
  title?: string;
}

export const DrawSection = observer(
  <TTabFields extends {}>({
    tabKey,
    title,
    objKey,
    objType,
    objLabel,
    attrs,
  }: PropsDrawSection<TTabFields>) => {
    const { objects, attrsMap, loadingAttrs } = schedulingSettingsStore;
    const form = Form.useFormInstance();
    const fkey = (name: keyof TTabFields) => String(name);
    const idObj = Form.useWatch([tabKey, fkey(objKey)]);

    React.useEffect(() => {
      schedulingSettingsStore.loadAttrs(objType, idObj);
    }, [idObj]);

    return (
      <Flex gap={12} vertical>
        {title && <div className={styles.title}>{title}</div>}
        <Form.Item
          name={[tabKey, fkey(objKey)]}
          label={objLabel}
          rules={[{ required: true }]}
        >
          <Select
            options={makeFieldOptions(objects)}
            showSearch
            optionFilterProp="filterProp"
            allowClear
            onChange={() => {
              form.resetFields(attrs.map(({ attrKey }) => [tabKey, attrKey]));
            }}
          />
        </Form.Item>
        {!!attrs.length && (
          <div className={classNames([[attrs.length > 1, styles.twoCols]])}>
            {attrs.map(({ attrKey, attrLabel, attrType }) => (
              <Form.Item
                key={fkey(attrKey)}
                name={[tabKey, fkey(attrKey)]}
                label={attrLabel}
                rules={[{ required: true }]}
              >
                <Select
                  options={
                    attrType
                      ? schedulingSettingsStore.makeOptionsByType(
                          attrType,
                          attrsMap[objType] ?? [],
                        )
                      : makeFieldOptions(attrsMap[objType] ?? [])
                  }
                  showSearch
                  optionFilterProp="filterProp"
                  allowClear
                  loading={loadingAttrs.has(objType)}
                  disabled={!idObj}
                />
              </Form.Item>
            ))}
          </div>
        )}
      </Flex>
    );
  },
);
