import React from "react";
import { Flex, Form, Select } from "antd";
import { observer } from "mobx-react-lite";
import { AttrTypeName } from "src/types/AttrType";
import { classNames } from "src/common/classNames";
import { makeFieldOptions } from "src/common/makeFieldOptions";
import styles from "./DrawSection.module.less";
import { ZTechpackSettings } from "../../TechpackTypes";
import {
  TechpackObjType,
  techpackSettingsStore,
} from "../TechpackSettingsStore";

interface AttrDef {
  attrKey: keyof ZTechpackSettings;
  attrLabel: string;
  attrType: AttrTypeName;
}

interface PropsDrawSection {
  objKey: keyof ZTechpackSettings;
  objType: TechpackObjType;
  attrs?: AttrDef[];
  objLabel?: string;
  required?: boolean;
}

const fieldKey = (name: keyof ZTechpackSettings) => name;

export const DrawSection: React.FC<PropsDrawSection> = observer(
  ({ objKey, objType, objLabel, attrs, required }) => {
    const { objects, attrsMap, loadingAttrs } = techpackSettingsStore;
    const form = Form.useFormInstance();
    const idObj = Form.useWatch(fieldKey(objKey));

    React.useEffect(() => {
      techpackSettingsStore.loadAttrs(objType, idObj);
    }, [idObj]);

    return (
      <Flex gap={12} vertical>
        <Form.Item
          name={fieldKey(objKey)}
          label={objLabel}
          rules={[{ required }]}
        >
          <Select
            options={makeFieldOptions(objects)}
            showSearch
            optionFilterProp="filterProp"
            allowClear
            onChange={() => {
              if (attrs?.length)
                form.resetFields(attrs.map(({ attrKey }) => fieldKey(attrKey)));
            }}
          />
        </Form.Item>

        {!!attrs?.length && (
          <div className={classNames([[attrs.length > 1, styles.twoCols]])}>
            {attrs.map(({ attrKey, attrLabel, attrType }) => (
              <Form.Item
                key={fieldKey(attrKey)}
                name={fieldKey(attrKey)}
                label={attrLabel}
                rules={[{ required }]}
              >
                <Select
                  options={techpackSettingsStore.makeOptionsByType(
                    attrType,
                    attrsMap[objType] ?? [],
                  )}
                  showSearch
                  optionFilterProp="filterProp"
                  allowClear
                  loading={loadingAttrs.has(objType)}
                  disabled={!idObj}
                />
              </Form.Item>
            ))}
          </div>
        )}
      </Flex>
    );
  },
);
