// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-SelectBomMaterial-SelectBomMaterial-module__tableBox--K7Mbb {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Position/SelectBomMaterial/SelectBomMaterial.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,YAAA;EACA,gBAAA;AACJ","sourcesContent":[".tableBox {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n    height: 100%;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBox": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-SelectBomMaterial-SelectBomMaterial-module__tableBox--K7Mbb`
};
export default ___CSS_LOADER_EXPORT___;
