import { ZSchedulingRow } from "src/businessServices/services/bsScheduling/SchedulingTypes";

export const searchTasks = (
  data: ZSchedulingRow[],
  searchTerm: string,
): ZSchedulingRow[] => {
  const lowerSearch = searchTerm.toLowerCase();

  const processItem = (item: ZSchedulingRow): ZSchedulingRow[] => {
    if (item.type === "PLAN") return [item];
    const nameMatches = item.name.toLowerCase().includes(lowerSearch);
    const matchedChildren = item.children?.flatMap(processItem) || [];
    if (nameMatches || matchedChildren.length) {
      return [
        {
          ...item,
          children: matchedChildren.length ? matchedChildren : null,
        },
      ];
    }
    return [];
  };

  return data.flatMap(processItem);
};
