import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import {
  theme,
  Button,
  Checkbox,
  Dropdown,
  Modal,
  Spin,
  notification,
} from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  ExpandAltOutlined,
  FilterOutlined,
  FunnelPlotOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { TableStore } from "src/components/tables/TableStore";
import { confirmDelete } from "src/components/ModalDelete";
import { onError } from "src/common/onError";
import { MChartEntityStore } from "../MChartEntityStore";
import { MCInfoItem } from "./MCInfoItem";
import { MChartTable } from "./MChartTable/MChartTable";
import { MChartCopyTo } from "./MChartCopyTo";
import { PointOpt } from "./MChartTable/PointDict";
import { FilterMcPoints, PointSelectModal } from "./PointSelectModal";
import styles from "./EditMCEntity.module.less";
import { McImageSwitcher } from "./McImageSwitcher";
import { McImageView } from "./McImageView";
import { McMoreTools, McMoreToolsData } from "./McMoreTools";

const { useToken } = theme;

interface PropsEditMCEntity {
  store: MChartEntityStore;
}

export const EditMCEntity: React.FC<PropsEditMCEntity> = observer(
  ({ store }) => {
    const { buzy, data, selected, hiddenColumns, categoryList } = store;
    const [openCopy, setOpenCopy] = React.useState(false);
    if (data.status !== "ready") return null;
    const { mc } = data.result;
    const disabled = !!buzy;
    const onReset = () => {
      Modal.confirm({
        title: t("Attention!"),
        content: t("All data will be deleted"),
        icon: <ExclamationCircleOutlined />,
        okButtonProps: { danger: true },
        onOk() {
          store.reset();
        },
      });
    };
    const onDeleteRow = () =>
      confirmDelete(t("Do you want to delete the selected records?"), () =>
        store.deleteSelected(),
      );
    const { token } = useToken();

    const contentStyle: React.CSSProperties = {
      backgroundColor: token.colorBgElevated,
      borderRadius: token.borderRadiusLG,
      boxShadow: token.boxShadowSecondary,
    };

    const [tableStore, setTableStore] = React.useState<TableStore<
      PointOpt,
      FilterMcPoints
    > | null>(null);
    const startAddPoints = () =>
      setTableStore(store.createPointSelectStore("checkbox"));
    const closeAddPoints = () => setTableStore(null);

    const onScale = () => {
      if (store.data.status !== "ready") {
        notification.warning({ message: t("Wait until saving finishes") });
      } else {
        const exec = () => store.scale();
        Modal.confirm({
          title: t("Overwrite measurement chart changes?"),
          content: t(
            "Scaling will change the dimension values relative to the base size.",
          ),
          type: "error",
          okButtonProps: { danger: true },
          okText: t("Overwrite"),
          onOk: exec,
        });
      }
    };

    return (
      <>
        <div className={styles.commonBox}>
          <div className={styles.infoRow}>
            <MCInfoItem
              label={t("Size scale")}
              value={{
                options: store.sizeLineOptions,
                value: store.curSizeLineValue,
                onChange: (v) => {
                  store.changeSizeLine(v).catch(onError);
                },
                loading: store.buzy === "sizeLine",
              }}
            />
            <MCInfoItem
              label={t("Base size")}
              value={{
                options: store.baseSizeOptions,
                value: store.curBaseSizeValue,
                onChange: (v) => {
                  store.changeBaseSize(v).catch(onError);
                },
                loading: store.buzy === "baseSize",
              }}
            />
            <MCInfoItem
              value={mc.measurementChartEntityDto?.comment}
              label={t("Comment")}
            />
          </div>
          <div className={styles.toolsRow}>
            <div className={styles.selectInfo}>
              {selected.size > 0 &&
                t("Selected lines count", { count: selected.size })}
            </div>
            <McImageSwitcher
              isVisible={store.isImagesVisible}
              toggle={() => store.setImageVisible(!store.isImagesVisible)}
            />
            <Dropdown
              trigger={["click"]}
              dropdownRender={() => (
                <div style={contentStyle} className={styles.dropDownBox}>
                  {store.sizeVersionsList.map(({ key, szName, verName }) => (
                    <div key={key}>
                      <Checkbox
                        checked={!hiddenColumns.has(key)}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          store.toggleColumnVisibility(key);
                        }}
                      >
                        {szName} - {verName}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              )}
            >
              <Button
                icon={
                  hiddenColumns.size ? (
                    <FunnelPlotOutlined />
                  ) : (
                    <FilterOutlined />
                  )
                }
                title={t("Columns visibility")}
              />
            </Dropdown>
            <Button
              icon={<DeleteOutlined />}
              danger
              disabled={disabled || selected.size === 0}
              loading={buzy === "deleteRow"}
              onClick={onDeleteRow}
            >
              {t("Delete selected")}
            </Button>
            <Button
              icon={<ExpandAltOutlined />}
              disabled={!store.canScale || disabled}
              loading={store.buzy === "scale"}
              onClick={onScale}
            >
              {t("Scale")}
            </Button>
            <McMoreTools
              data={
                {
                  copyToModel: {
                    disabled,
                    action: () => setOpenCopy(true),
                  },
                  reset: {
                    disabled,
                    loading: buzy === "reset",
                    action: onReset,
                  },
                } satisfies McMoreToolsData
              }
            />
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              disabled={disabled}
              onClick={startAddPoints}
              loading={buzy === "createRow"}
            >
              {t("Add points")}
            </Button>
          </div>
          <div className={styles.mainContent}>
            {store.buzy && (
              <div className={styles.spin}>
                <Spin size="large" />
              </div>
            )}
            <MChartTable store={store} />
            {store.isImagesVisible && <McImageView store={store} />}
          </div>
        </div>
        <MChartCopyTo
          store={store}
          open={openCopy}
          close={() => setOpenCopy(false)}
        />
        <PointSelectModal
          title={t("Select points to create")}
          tableStore={tableStore}
          categoryList={categoryList}
          close={closeAddPoints}
          success={(rows) => store.createRows(rows).catch(onError)}
        />
      </>
    );
  },
);
