import { rest } from "src/common/rest";
import { apiPlanning } from "src/common/apiUrl";
import { zEntity, ZEntity } from "src/types/ZEntity";
import {
  ZSchedulingRow,
  zSchedulingRow,
  zSchedulingSettings,
  ZSchedulingSettings,
} from "./SchedulingTypes";

export const loadSchedulingSettings = async (
  id: number,
): Promise<ZSchedulingSettings> => {
  const resp = await rest.get(apiPlanning(`/settings/${id}`));
  return zSchedulingSettings.parse(resp.data);
};

export const saveSchedulingSettings = async (
  id: number,
  settings: ZSchedulingSettings,
) => {
  await rest.post(apiPlanning(`/settings/${id}`), settings);
};

export const loadSchedulingData = async (
  entityId: number,
  objectServiceId: number,
): Promise<ZSchedulingRow> => {
  const resp = await rest.get(
    apiPlanning(`/plan/${objectServiceId}/${entityId}`),
  );
  return zSchedulingRow.parse(resp.data);
};

export const createTaskFromTemplate = async (
  entityId: number,
  templateEntityId: number,
  objectServiceId: number,
) => {
  await rest.post(apiPlanning("/plan/createTaskFromTemplate"), {
    entityId,
    templateEntityId,
    objectServiceId,
  });
};

export const createGroupFromTemplate = async (
  entityId: number,
  templateEntityId: number,
  objectServiceId: number,
) => {
  await rest.post(apiPlanning("/plan/createGroupFromTemplate"), {
    entityId,
    templateEntityId,
    objectServiceId,
  });
};

export const createPlanFromTemplate = async (
  entityId: number,
  templateEntityId: number,
  objectServiceId: number,
) => {
  await rest.post(apiPlanning("/plan/createPlanFromTemplate"), {
    entityId,
    templateEntityId,
    objectServiceId,
  });
};

export const createTask = async (
  entityId: number,
  task: ZEntity,
  objectServiceId: number,
): Promise<ZEntity> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...other } = task;
  const result = await rest.post(
    apiPlanning(`/plan/${objectServiceId}/${entityId}/tasks`),
    other,
  );
  return zEntity.parse(result);
};

export const deleteTasks = async (
  entityId: number,
  taskIds: number[],
  objectServiceId: number,
) => {
  await rest.delete(apiPlanning(`/plan/${objectServiceId}/${entityId}/tasks`), {
    data: taskIds,
  });
};

export const saveSchedStartDate = async (
  entityId: number,
  startDate: string,
  objectServiceId: number,
) => {
  await rest.post(apiPlanning("/plan/dates"), {
    entityId,
    startDate,
    objectServiceId,
  });
};
