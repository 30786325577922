import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  ZSchedSettingsTask,
  ZSchedulingSettings,
} from "../../../SchedulingTypes";
import { DrawSection } from "./DrawSection";
import { SchedulingObjType } from "../../SchedulingSettingsStore";

interface PropsTaskTiesSection {
  tabKey: keyof ZSchedulingSettings;
  objType: SchedulingObjType;
}

export const TaskTiesSection: React.FC<PropsTaskTiesSection> = ({
  tabKey,
  objType,
}) => (
  <DrawSection<ZSchedSettingsTask>
    tabKey={tabKey}
    objKey="relationObjectId"
    objType={objType}
    objLabel="Объект для формирования зависимости задач"
    title="Привязки"
    attrs={[
      {
        attrKey: "previousAttributeId",
        attrLabel: "Укажите атрибут предыдущей задачи",
        attrType: AttrTypeName.object,
      },
      {
        attrKey: "relationTypeAttributeId",
        attrLabel: "Укажите атрибут типа привязки",
        attrType: AttrTypeName.dictSingle,
      },
      {
        attrKey: "deltaDaysAttributeId",
        attrLabel: "Укажите атрибут кол-ва дней задержки/опережения",
        attrType: AttrTypeName.int,
      },
    ]}
  />
);
