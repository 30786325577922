import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import { DrawTab } from "./DrawTab";
import { ZSchedSettingsTask } from "../../SchedulingTypes";
import { TaskTiesSection } from "./sections";
import { DrawSection } from "./sections/DrawSection";

export const TaskFields: React.FC = () => (
  <DrawTab>
    <DrawSection<ZSchedSettingsTask>
      tabKey="taskSettings"
      objKey="templateObjectId"
      objType="task"
      objLabel="Объект для формирования шаблона задачи"
      title="Список обязательных позиций"
      attrs={[
        {
          attrKey: "nameAttributeId",
          attrLabel: "Укажите атрибут названия задачи",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: "durationAttributeId",
          attrLabel: "Укажите атрибут длительности задачи",
          attrType: AttrTypeName.int,
        },
        {
          attrKey: "groupAttributeId",
          attrLabel: "Укажите атрибут принадлежности задачи к группе задач",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: "modelLinkAttributeId",
          attrLabel: "Укажите атрибут принадлежности задачи к товарной позиции",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: "durationLinkAttributeId",
          attrLabel:
            "Укажите атрибут принадлежности задачи к длительности задачи",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: "relationLinkAttributeId",
          attrLabel: "Укажите атрибут принадлежности задачи к привязкам задачи",
          attrType: AttrTypeName.object,
        },
      ]}
    />
    <TaskTiesSection objType="taskTies" tabKey="taskSettings" />
    <DrawSection<ZSchedSettingsTask>
      tabKey="taskSettings"
      objKey="deadlineObjectId"
      objType="taskTerms"
      objLabel="Объект для формирования сроков задачи"
      title="Сроки"
      attrs={[
        {
          attrKey: "plannedStartDateAttributeId",
          attrLabel: "Укажите атрибут плановой даты начала задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "plannedEndDateAttributeId",
          attrLabel: "Укажите атрибут плановой даты окончания задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "settlementStartDateAttributeId",
          attrLabel: "Укажите атрибут расчетной даты начала задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "settlementEndDateAttributeId",
          attrLabel: "Укажите атрибут расчетной даты окончания задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "actualStartDateAttributeId",
          attrLabel: "Укажите атрибут фактической даты начала задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "actualEndDateAttributeId",
          attrLabel: "Укажите атрибут фактической даты окончания задачи",
          attrType: AttrTypeName.date,
        },
      ]}
    />
  </DrawTab>
);
