// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__form--x1aAQ {
  height: 100%;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__formContent--BstCz {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow: auto;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstPart--WTuGl {
  display: flex;
  gap: 12px;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstLeft--PjA7l {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstRight--ZU37w {
  width: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Position/Bom2Table/BomColorCell/BomColorModal.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,YAAA;EACA,cAAA;AACJ;AACA;EACI,aAAA;EACA,SAAA;AACJ;AACA;EACI,OAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACA;EACI,YAAA;AACJ","sourcesContent":[".form {\n    height: 100%;\n}\n.formContent {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    height: 100%;\n    overflow: auto;\n}\n.firstPart {\n    display: flex;\n    gap: 12px;\n}\n.firstLeft {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n.firstRight {\n    width: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__form--x1aAQ`,
	"formContent": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__formContent--BstCz`,
	"firstPart": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstPart--WTuGl`,
	"firstLeft": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstLeft--PjA7l`,
	"firstRight": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstRight--ZU37w`
};
export default ___CSS_LOADER_EXPORT___;
