import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { zObjectItem, ZObjectItem } from "src/types/ZObjectItem";

export const importObj = async (xml: string): Promise<ZObjectItem> => {
  const newObj = await rest.post(apiObjUrl("/data-transfer/import"), xml, {
    headers: { "Content-Type": "application/xml" },
  });
  return zObjectItem.parse(newObj.data);
};
