import React from "react";
import { Flex, Form, Input, Select, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { AttrTypeName } from "src/types/AttrType";
import { ZTechpackSettings } from "../TechpackTypes";
import { techpackSettingsStore } from "./TechpackSettingsStore";
import { DrawSection } from "./DrawSection";

interface PropsTechpackSettings {
  typesMap: Record<number, string>;
  objectId: number;
  serviceObjectId: number;
}

const fieldKey = (key: keyof ZTechpackSettings) => key;

export const TechpackSettings: React.FC<PropsTechpackSettings> = observer(
  ({ typesMap, objectId, serviceObjectId }) => {
    React.useEffect(() => {
      techpackSettingsStore.init(typesMap, objectId);
    }, [serviceObjectId, objectId]);

    const { loading, attrsMap, loadingAttrs } = techpackSettingsStore;

    return (
      <Spin spinning={loading}>
        <Flex gap={12} vertical>
          <DrawSection
            objKey="techpackObjectId"
            objType="techpack"
            objLabel="Объект для формирования"
            required
          />
          <Form.Item
            label="URL для формирования"
            name={fieldKey("techpackUrl")}
            rules={[trimRequired()]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="Артикул модели"
            name={fieldKey("articleAttributeId")}
          >
            <Select
              options={techpackSettingsStore.makeOptionsByType(
                AttrTypeName.article,
                attrsMap.model ?? [],
              )}
              showSearch
              optionFilterProp="filterProp"
              allowClear
              loading={loadingAttrs.has("model")}
            />
          </Form.Item>
          <DrawSection
            objKey="supplierObjectId"
            objType="supplier"
            objLabel="Объект поставщика"
            attrs={[
              {
                attrKey: "supplierNameAttributeId",
                attrLabel: "Имя поставщика",
                attrType: AttrTypeName.string,
              },
            ]}
          />
        </Flex>
      </Spin>
    );
  },
);
