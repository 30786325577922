import * as React from "react";
import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import { SheetStore } from "./SheetStore";
import { onError } from "../../common/onError";
import styles from "./CellBox.module.less";

export type FnFocus = (() => void) | undefined;

export type PropsCellBoxChildren<TValue> = {
  value: TValue | undefined;
  onChange: (v: TValue) => void;
  onBlur: () => void;
  getFocus: (fn: FnFocus) => void;
};

export type PropsCellBox<TValue> = {
  cellKey: string; // Должен быть уникальным для таблицы. То есть, включает id строки и колонки
  value: TValue;
  store: SheetStore;
  save: (value: TValue | undefined) => Promise<void>;
  checkValue?: (v: TValue) => string | null;
  disableEnter?: boolean;

  children: (props: PropsCellBoxChildren<TValue>) => React.ReactNode;
};

export const CellBox = observer(<TValue,>(props: PropsCellBox<TValue>) => {
  const { cellKey, value, store, save, checkValue, children, disableEnter } =
    props;
  const isActive = store.activeKey === cellKey;
  const getDraft = () => store.getCellValue<TValue>(cellKey);
  const setDraft = (v: TValue | undefined) => {
    store.setCellValue(cellKey, v);
  };

  React.useEffect(() => {
    setDraft(value);
  }, [value]);

  const error = store.getError(cellKey);
  const cancel = () => {
    setDraft(value);
    store.setError(cellKey, null);
  };
  React.useEffect(() => {
    const onKey = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        cancel();
        return;
      }
      if (!disableEnter && e.code === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        onBlur();
      }
    };
    if (isActive) {
      window.addEventListener("keydown", onKey);
      return () => window.removeEventListener("keydown", onKey);
    }
    return undefined;
  }, [isActive]);

  const onChange = (newValue: TValue) => {
    setDraft(newValue);
    store.setError(cellKey, checkValue?.(newValue) ?? null);
  };
  const saveCell = async () => {
    const valueToSave = getDraft();
    if (valueToSave !== value && !error) {
      store.setWait(cellKey);
      try {
        await save(valueToSave);
      } catch (e) {
        onError(e);
        store.setError(cellKey, e.message);
      } finally {
        store.clearWait(cellKey);
      }
    }
  };
  const onBlur = () => {
    saveCell().catch(onError);
  };
  const fnFocus = React.useRef<FnFocus>();
  const focus = () => {
    fnFocus?.current?.();
  };

  return (
    <div
      tabIndex={isActive ? undefined : 0}
      className={styles.cellBox}
      onFocus={() => {
        if (
          store.tryToSetActive({
            key: cellKey,
            focus,
          })
        ) {
          focus();
        }
      }}
    >
      <Spin spinning={store.waits.has(cellKey)}>
        {children({
          value: getDraft(),
          onChange,
          onBlur,
          getFocus(fn: FnFocus) {
            fnFocus.current = fn;
          },
        })}
      </Spin>
      {!!error && <div className={styles.cellError}>{error}</div>}
    </div>
  );
});
