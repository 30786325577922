import { AttrTypeName } from "src/types/AttrType";
import { ZAttrComponentView } from "./zAttrViewInfo";

export const viewsByType = {
  [AttrTypeName.boolean]: ["SimpleText", "Tag"],
  [AttrTypeName.date]: ["SimpleText", "Tag"],
  [AttrTypeName.dateTime]: ["SimpleText", "Tag"],
  [AttrTypeName.dictMulti]: ["DictRef"],
  [AttrTypeName.dictSingle]: ["DictRef"],
  [AttrTypeName.file]: ["FileList", "LinkStd"],
  [AttrTypeName.image]: ["ImgList", "LinkStd"],
  [AttrTypeName.int]: ["SimpleText", "Tag"],
  [AttrTypeName.number]: ["SimpleText", "Tag"],
  [AttrTypeName.string]: ["SimpleText", "Tag"],
  [AttrTypeName.time]: ["SimpleText", "Tag"],
  [AttrTypeName.object]: ["ObectRef"],
  [AttrTypeName.usersList]: ["PersonCellInfo"],
  [AttrTypeName.childEntities]: ["ChildEntitiesCellInfo"],
  [AttrTypeName.externalLink]: ["LinkView"],
  [AttrTypeName.formula]: ["AttrFormulaCell"],
  [AttrTypeName.contentLanguage]: ["ContentLangInfo"],
  [AttrTypeName.linkedValue]: ["AttrLinked"],
  [AttrTypeName.article]: [],
} satisfies Record<AttrTypeName, ZAttrComponentView["view"][]>;
