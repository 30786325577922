// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Settings-BomSection-BomSection-module__section--sAJ53 {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.src-businessServices-services-bsBom2-Bom2Settings-BomSection-BomSection-module__attrList--zqP0I {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 8px;
}
.src-businessServices-services-bsBom2-Bom2Settings-BomSection-BomSection-module__objectItem--dSbFu label {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Settings/BomSection/BomSection.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AAEA;EACI,aAAA;EACA,kCAAA;EACA,gBAAA;EACA,YAAA;AAAJ;AAEA;EAEQ,iBAAA;AADR","sourcesContent":[".section {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n\n.attrList {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    column-gap: 16px;\n    row-gap: 8px;\n}\n.objectItem {\n    label {\n        font-weight: bold;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `src-businessServices-services-bsBom2-Bom2Settings-BomSection-BomSection-module__section--sAJ53`,
	"attrList": `src-businessServices-services-bsBom2-Bom2Settings-BomSection-BomSection-module__attrList--zqP0I`,
	"objectItem": `src-businessServices-services-bsBom2-Bom2Settings-BomSection-BomSection-module__objectItem--dSbFu`
};
export default ___CSS_LOADER_EXPORT___;
